<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="1500px">
      <v-card light>
        <v-card-title>
          <span>Termine - {{ patient.name }}, {{ patient.vorname }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-card elevation="1" class="mb-2">
                  <v-card-title class="text-overline"
                    >Patientendaten</v-card-title
                  >
                  <v-card-subtitle v-if="patient.kt_typ_name">
                    {{ patient.kt_typ_name }}
                  </v-card-subtitle>

                  <v-card-subtitle v-if="!patient.kt_typ_name">
                    Unerfasster Patient
                  </v-card-subtitle>

                  <v-list two-line>
                    <v-list-item class="mb-n2">
                      <v-list-item-icon>
                        <v-icon color="primary"> mdi-account</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ patient.vorname }}
                          {{ patient.name }}</v-list-item-title
                        >
                        <v-list-item-subtitle v-if="patient.anrede"
                          >{{ patient.anrede }} {{ patient.titel }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="!patient.anrede"
                          >-
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="mb-n2" v-if="patient.geburtsdatum">
                      <v-list-item-icon>
                        <v-icon color="primary"> mdi-cake-variant </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          patient.geburtsdatum | formatDate
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Geburtsdatum
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="mb-n2" v-if="patient.strasse">
                      <v-list-item-icon>
                        <v-icon color="primary"> mdi-map-marker </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          patient.strasse
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >{{ patient.plz }},
                          {{ patient.ort }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <div
                      v-if="
                        patient.telefon_priv ||
                          patient.telefon_mob ||
                          patient.telefon_arb
                      "
                    >
                      <v-divider inset></v-divider>
                      <v-list-item class="mb-n2" v-if="patient.telefon_priv">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-phone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.telefon_priv
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Privat</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="mb-n2" v-if="patient.telefon_mob">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-cellphone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.telefon_mob
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Mobil</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="mb-n2" v-if="patient.telefon_arb">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-deskphone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.telefon_arb
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Arbeit</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>

                    <div v-if="['KK', 'PBeaKK', 'S'].includes(patient.kt_typ)">
                      <v-divider inset></v-divider>
                      <v-list-item class="mb-n2">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-office-building </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.kostentraeger_name
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            patient.kostentraeger_ik_num
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="mb-n2">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-alpha-s-circle </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.vers_status
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            Versicherungstatus
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="mb-n2">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-alpha-n-circle </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.vers_nummer
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Versicherungsnummer
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>

                    <div v-if="patient.kt_typ == 'BG'">
                      <v-divider inset></v-divider>
                      <v-list-item class="mb-n2">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-office-building </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.kostentraeger_name
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            patient.kostentraeger_ik_num
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="mb-n2">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-calendar </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.unfalltag
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Unfalltag</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>

                    <div
                      v-if="
                        patient.kt_typ == 'PP' && patient.alt_rechnungsadresse
                      "
                    >
                      <v-divider inset></v-divider>
                      <v-subheader>Abweichende Rechnungsadresse</v-subheader>

                      <v-list-item class="mb-n2">
                        <v-list-item-icon>
                          <v-icon color="primary"> mdi-account-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ patient.alt_vorname }}
                            {{ patient.alt_name }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >{{ patient.alt_anrede }} {{ patient.alt_titel }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="mb-n2">
                        <v-list-item-icon>
                          <v-icon color="primary">
                            mdi-map-marker-outline
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            patient.alt_strasse
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >{{ patient.alt_plz }},
                            {{ patient.alt_ort }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list>
                </v-card>
              </v-col>

              <v-col cols="9">
                <v-row>
                  <v-col cols="3">
                    <v-menu
                      v-model="menu.from_date"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="from_date"
                          label="Datum von"
                          prepend-icon="mdi-calendar-start"
                          v-model="selections.from_date"
                          v-on="on"
                          @input="handleInputDateText('from_date')"
                          @focus="handleFocusDateText('from_date')"
                          placeholder="TT.MM.JJJJ"
                          dense
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.from_date"
                        @input="handleInputDatePicker('from_date')"
                        first-day-of-week="1"
                        locale="de-de"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="menu.to_date"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="to_date"
                          label="Datum bis"
                          prepend-icon="mdi-calendar-end"
                          v-model="selections.to_date"
                          v-on="on"
                          @input="handleInputDateText('to_date')"
                          @focus="handleFocusDateText('to_date')"
                          placeholder="TT.MM.JJJJ"
                          dense
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.to_date"
                        @input="handleInputDatePicker('to_date')"
                        first-day-of-week="1"
                        locale="de-de"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      ref="status"
                      v-model="filter.status"
                      :items="options.status"
                      prepend-icon="mdi-calendar-question"
                      label="Status"
                      clearable
                      dense
                      @focus="closeMenu()"
                    ></v-select>
                  </v-col>
                  <v-col align="right">
                    <v-btn
                      color="tertiary"
                      small
                      dark
                      @click="createAppointmentNote()"
                      :disabled="termine.length == 0"
                    >
                      <v-icon left>
                        mdi-calendar-export
                      </v-icon>

                      Zusammenfassung</v-btn
                    ></v-col
                  >
                </v-row>

                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="termine"
                  :server-items-length="totalTermine"
                  :options.sync="dataOptions"
                  
                  show-expand
                  :footer-props="{
                    itemsPerPageText: 'Einträge pro Seite',
                    itemsPerPageAllText: 'Alle',
                  }"
                  loading-text="Daten werden geladen... Bitte warten"
                  :single-expand="true"
                  class="elevation-1"
                >
                  <template #footer.page-text="props">
                    {{ props.pageStart }}-{{ props.pageStop }} von
                    {{ props.itemsLength }}
                  </template>

                  <template slot="no-data">
                    <div v-if="termine.length == 0">
                      Keinen Termine gefunden!
                    </div>
                  </template>

                  <template v-slot:item.start="{ item }">
                    <span class="weekday">{{ item.start | formatDateWeekday }}</span>
                    <span>{{ item.start | formatDate }}</span>
                  </template>

                  <template v-slot:item.duration="{ item }">
                    {{ getDuration(item.start, item.end) }}
                  </template>

                  <template v-slot:item.time="{ item }">
                    {{ item.start | formatTime }}
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      {{ behandlungenSummary(item) }}
                    </td>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="move(item)"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="isItemDisabled(item)"
                          class="mr-2"
                        >
                          mdi-update
                        </v-icon>
                      </template>
                      <span>Verschieben</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="createAppointmentNote(item)"
                        >
                          mdi-calendar-export
                        </v-icon>
                      </template>
                      <span>Terminzettel</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-nav-btn" color="primary" text @click="closeWindow">Zurück</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";

const initialData = () => ({
  componentName: "PatientTermine",
  headers: [
    {
      text: "Datum",
      value: "start",
      sortable: true,
      align: "start",
    },
    {
      text: "Uhrzeit",
      value: "time",
      sortable: false,
      align: "start",
    },

    {
      text: "Dauer (min)",
      value: "duration",
      sortable: false,
      align: "start",
    },

    {
      text: "Mitarbeiter",
      value: "category",
      sortable: true,
      align: "start",
    },

    {
      text: "Status",
      value: "status",
      sortable: false,
    },

    { text: "", value: "data-table-expand", sortable: false },
    {
      text: "Aktionen",
      value: "actions",
      sortable: false,
      align: "center",
    },
  ],
  dataOptions: {
    sortBy: ["start"],
    sortDesc: [false],
    page: 1,
    itemsPerPage: 10,
    multiSort: true,
  },

  loading: false,
  options: {
    status: ["Offen", "Erledigt", "Abgesagt"],
  },

  menu: {
    from_date: false,
    to_date: false,
  },
  filter: {
    status: null,
    from_date: null,
    to_date: null,
  },

  selections: {
    from_date: null,
    to_date: null,
  },

  termine: [],
  totalTermine: 0,

  filtersUpdated: false,
});

export default {
  name: "PatientTermine",

  props: ["switchToTermin"],
  data() {
    return initialData();
  },

  filters: {
    formatDate(value) {
      return value ? moment(String(value)).format("DD.MM.YYYY") : "";
    },

    formatTime(value) {
      return value ? moment(String(value)).format("HH:mm") : "";
    },

    formatDateWeekday(value) {
      moment.locale('de');
      return value ? moment(String(value)).format("ddd") : "";
    },
  },


  computed: {
    active() {
      return this.$store.getters["overlays/patientTermineActive"];
    },

    filters() {
      return [this.filter.from_date, this.filter.to_date, this.filter.status];
    },

    patient() {
      let patient = this.$store.getters["overlays/patientTerminePatient"];
      return patient ? patient : {};
    },
  },

  methods: {
    ...mapActions("overlays", [
      "closeAndClearPatientTermine",
    ]),

    ...mapActions("termine", ["getTerminZettel", "queryTermine"]),

    ...mapMutations("overlays", ["openTerminMove"]),

    move(item) {
      let data = {
        componentName: this.componentName,
        termin: item,
      };
      this.switchToTermin(item);
      this.openTerminMove(data);
    },

    getOrder() {
      // iterate over sortBy and sortDesc e.g.
      // sortBy: ["start", "end"],
      // sortDesc: [false, true]
      // and return "start,-end"
      let order = [];
      for (let i = 0; i < this.dataOptions.sortBy.length; i++) {
        let desc = this.dataOptions.sortDesc[i] ? "-" : "";
        let name = this.dataOptions.sortBy[i];
        if (name == "category") {
          name = "mitarbeiter";
        }
        order.push(desc + name);
      }
      return order.join(",");
    },

    async getTermine() {
      // two possible cases
      // either Patient is "erfasst" or not
      let params = {
        patientId: this.patient.id,
        page: this.dataOptions.page,
        pageSize: this.dataOptions.itemsPerPage,
      };

      if (this.dataOptions.sortBy) {
        params["orderBy"] = this.getOrder();
      }

      if (this.filter.from_date) {
        params["fromDate"] = this.filter.from_date;
      }

      if (this.filter.to_date) {
        params["toDate"] = this.filter.to_date;
      }

      if (this.filter.status) {
        params["status"] = this.filter.status;
      }

      this.loading = true;

      let data = {};
      try {
        data = await this.queryTermine(params);
      } catch (e) {
        if (e.response.status == 400){
          this.dataOptions.page = 1
          data = await this.queryTermine(params);
        }
      }

      this.termine = data.results;
      this.totalTermine = data.totalEntries;
      this.loading = false;
    },

    async createAppointmentNote(item = null) {
      let name = this.patient.name + this.patient.vorname[0];
      let pdf_name = [
        "terminzettel_",
        name,
        "_",
        new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "_"),
        ".pdf",
      ].join("");

      let data = {
        pdf_name: pdf_name,
      };

      if (item) {
        data["gruppen_id"] = item.gruppen_id;
      } else {
        data["patient_id"] = this.patient.id;
      }

      await this.getTerminZettel(data);
    },

    isItemDisabled(item) {
      let s = moment(item.start).utc(true);
      let n = moment().utc(true);

      return !n.isBefore(s) && item.status == "Erledigt";
    },

    getDuration(start, end) {
      let s = moment(start).utc(true);
      let e = moment(end).utc(true);

      return e.diff(s, "minutes");
    },

    behandlungenSummary(item) {
      let summary_parts = [];
      let counted_parts = [];

      for (const beh of item.behandlungen) {
        summary_parts.push(beh[0]);
      }

      const map = summary_parts.reduce(
        (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
        new Map()
      );

      [...map.entries()].forEach((element) => {
        if (element[1] > 1) {
          counted_parts.push(element[1] + "x " + element[0]);
        } else {
          counted_parts.push("1x " + element[0]);
        }
      });

      return counted_parts.join(" + ");
    },

    closeWindow() {
      // reset data
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));

      this.closeAndClearPatientTermine();
    },

    handleInputDateText(name) {
      if (this.selections[name]) {
        let m = moment(this.selections[name], "DD.MM.YYYY", true);
        if (m.isValid()) {
          this.filter[name] = m.format("YYYY-MM-DD");
          
          setTimeout(() => {
            this.menu[name] = false;
          }, 200);

        } else {
          this.selections[name] = null;
          this.filter[name] = null;
        }
      } else {
        this.selections[name] = null;
        this.filter[name] = null;
      }
    },

    handleFocusDateText(name) {
      var menu_names = ["from_date", "to_date"].filter((e) => e !== name);

      setTimeout(() => {
        if (!this.menu[name]) {
          this.menu[name] = true;
        }
        for (const n of menu_names) {
          this.menu[n] = false;
        }
      }, 200);
    },

    closeMenu() {
      var menu_names = ["from_date", "to_date"];

      for (const n of menu_names) {
        this.menu[n] = false;
      }
    },

    handleInputDatePicker(name) {
      let focus_lkp = {
        from_date: ["to_date"],
        to_date: ["status"],
      };

      // close picker
      this.menu[name] = false;

      // update selections
      this.selections[name] = moment(this.filter[name]).format("DD.MM.YYYY");

      // focus next element
      let resolution_order = focus_lkp[name];
      for (var element in resolution_order) {
        if (this.$refs[resolution_order[element]]) {
          let next = resolution_order[element];

          if (next == "status") {
            this.$refs[next].$refs.input.focus();
          } else {
            this.$refs[next].focus();
          }
          break;
        }
      }
    },
  },
  watch: {
    async active(val) {
      if (val) {
        await this.getTermine();
      }
    },

    async filters() {
      if (this.active) {
        // Reset dataOptions page number to 1 on filter change
        this.dataOptions.page = 1;
        this.filtersUpdated = true;
        await this.getTermine();
        this.filtersUpdated = false;
      }
    },

    async dataOptions() {
      if (this.active) {
        if (!this.filtersUpdated) {
          await this.getTermine();
        }
      }
    },
  },

  mounted() {},
};
</script>

<style>
.weekday {
  width: 32px;
  display: inline-grid;
}
</style>
