import axios from "axios";
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

const state = {
  oeffnungszeiten: [],
  geschlossen: [],
};

const getters = {
  getOeffnungszeiten: (state) => {
    return state.oeffnungszeiten;
  },

  getGeschlossen: (state) => {
    return state.geschlossen;
  },

  isOpen: (state) => (weekdayNum) => {
    return state.oeffnungszeiten[weekdayNum].open;
  },

  getFirstOpenInterval: (state, getters) => (weekdayNum) => {
    if (getters.isOpen(weekdayNum)) {
      return [...state.oeffnungszeiten[weekdayNum].times[0]];
    }
    return null;
  },

  getMinOpeningHour: (state, getters) => (weekdayNum) => {
    if (getters.isOpen(weekdayNum)) {
      return state.oeffnungszeiten[weekdayNum].times[0][0];
    }
    return null;
  },

  getMaxOpeningHour: (state, getters) => (weekdayNum) => {
    if (getters.isOpen(weekdayNum)) {
      return state.oeffnungszeiten[weekdayNum].times.slice(-1)[0][1];
    }
    return null;
  },

  getNextValidTime: (state, getters) => (weekdayNum, time) => {
    if (getters.isOpen(weekdayNum)) {
      let checkTime = moment("2022-01-01 " + time);
      let openEntries = state.oeffnungszeiten[weekdayNum].times;
      for (let [start, end] of openEntries) {
        let openInterval = moment.range(moment("2022-01-01 " + start), moment("2022-01-01 " + end));
        if (openInterval.contains(checkTime, { excludeEnd: true })) {
          return time;
        } else {
          if (openInterval.start.isAfter(checkTime)) {
            return openInterval.start.format("HH:mm");
          }
        }
      }
    }
    return null;
  },

  getNextMaxTime: (state, getters) => (weekdayNum, time) => {
    if (getters.isOpen(weekdayNum)) {
      let checkTime = moment("2022-01-01 " + time);
      let openEntries = state.oeffnungszeiten[weekdayNum].times;
      for (let [start, end] of openEntries) {
        let openInterval = moment.range(moment("2022-01-01 " + start), moment("2022-01-01 " + end));
        if (openInterval.contains(checkTime, { excludeEnd: true })) {
          return openInterval.end.format("HH:mm");
        }
      }
    }
    return null;
  },

  isValidInterval: (state) => (weekdayNum, interval) => {
    let checkInterval = moment.range(moment("2022-01-01 " + interval[0]), moment("2022-01-01 " + interval[1]));

    if (!state.oeffnungszeiten[weekdayNum].open) {
      return false;
    }

    let closedEntries = state.geschlossen[weekdayNum];
    for (let [start, end] of closedEntries) {
      let closedInterval = moment.range(moment("2022-01-01 " + start), moment("2022-01-01 " + end));
      if (closedInterval.overlaps(checkInterval)) {
        return false;
      }
    }

    return true;
  },
};

const actions = {
  async GetOeffnungszeitenData({ commit, rootGetters }) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    return axios
      .get("oeffnungszeiten/", config)
      .then((res) => {
        commit("setOeffnungszeitenData", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // TODO
  async BulkOperationsOeffnungszeiten({ commit, rootGetters }, data) {
    /* 
    [
      {wochentag: 0, start: timestr, ende: timestr},
    ]
    }
    */
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    try {
      const response = await axios.post("oeffnungszeiten/bulk/", data, config);
      commit("setOeffnungszeitenData", response.data);
    } catch (error) {
      throw "Some error " + error;
    }
  },
};

const mutations = {
  setOeffnungszeitenData(state, data) {
    state.oeffnungszeiten = data.open;
    state.geschlossen = data.closed;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
