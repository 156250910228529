import axios from "axios";
import moment from "moment";

const getDefaultState = () => {
  return {
    snackbars: {
      add_select: false,
      add_success: false,
      change_success: false,
      add_repick: false,
      mitarbeiter_busy: false,
      past_termin: false,
    },
    add: {
      active: false,
      visible: false,
      disabled: false,
      selecting: false,
      selectHeilmittelActive: false,
      repicking: false,
      repick_id: null,

      termintypes: ["Behandlung", "Intern"],
      terminmodes: ["Standardtermin", "Serientermin"],
      treatmentTypes: ["Standard", "Vollprivat"],
      selected: {
        termin_key: 0,
        termintyp: "Behandlung",
        terminmode: "Standardtermin",
        treatmentType: "Standard",
        patient: null,
        patient_neu_flag: false,
        patient_name: "",
        patient_vorname: "",
        patient_geburtsdatum: null,
        patient_telefon: "",
        mitarbeiter: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        heilmittel: null,
        behandlungen: [],
        behandlungen_selected: [],
        bemerkung: "",
        name: "",
        ganztag: false,
        color: {
          hexa: "#B43962FF",
        },
        termine: [],
      },
    },

    edit: {
      active: false,
      visible: false,
      disabled: false,
      termingruppe: null,
      termine: [],
    },

    drag_confirmation: {
      previous: null,
      active: false,
      drag: false,
      drag_category: false,
      extend: false,
      termin: null,
      original_start: null,
      original_end: null,
      original_category: null,
      errors: [],
      move: false,
      move_category: false,
    },

    add_patient: {
      active: false,
      item: null,
    },

    add_doctor: {
      active: false,
      defaults: null,
      added: [],
    },

    termin_move: {
      active: false,
      previous: null,
      termin: {
        current: null,
        new: null,
      },
    },

    patienten_overview: {
      active: false,
      loading: false,
    },

    patient_termine: {
      active: false,
      patient: null,
      previous: null,
    },

    nacherfassung: {
      active: false,
      loading: false,
    },

    settings: {
      active: false,
      loading: false,
      timeunits: {
        selected: {
          heilmittel: null,
        },
      },
    },

    rezept: {
      active: false,
      item: null,
    },

    timeout: {
      active: false,
    },

    termin_reject: {
      active: false,
      termin_id: null,
    },
  };
};

// Initial state
const state = getDefaultState();

const getters = {
  dragConfirmationActive: (state) => {
    return state.drag_confirmation.active;
  },

  dragConfirmationDrag: (state) => {
    return state.drag_confirmation.drag;
  },

  dragCategoryConfirmationDrag: (state) => {
    return state.drag_confirmation.drag_category;
  },

  dragConfirmationMove: (state) => {
    return state.drag_confirmation.move;
  },

  dragCategoryConfirmationMove: (state) => {
    return state.drag_confirmation.move_category;
  },

  dragConfirmationExtend: (state) => {
    return state.drag_confirmation.extend;
  },

  dragConfirmationTermin: (state) => {
    return state.drag_confirmation.termin;
  },

  dragConfirmationErrors: (state) => {
    return state.drag_confirmation.errors;
  },

  terminRejectActive: (state) => {
    return state.termin_reject.active;
  },

  terminRejectId: (state) => {
    return state.termin_reject.termin_id;
  },

  patientTerminePatient: (state) => {
    return state.patient_termine.patient;
  },


  doctorAddActive: (state) => {
    return state.add_doctor.active;
  },

  doctorAddDefaults: (state) => {
    return state.add_doctor.defaults;
  },

  doctorLastAdded: (state) => {
    if (state.add_doctor.added.length > 0) {
      return state.add_doctor.added[state.add_doctor.added.length - 1];
    }
    return null;
  },


  patientAddActive: (state) => {
    return state.add_patient.active;
  },

  patientNacherfassungItem: (state) => {
    return state.add_patient.item;
  },

  nacherfassungActive: (state) => {
    return state.nacherfassung.active;
  },

  settingsActive: (state) => {
    return state.settings.active;
  },

  patientenOverviewActive: (state) => {
    return state.patienten_overview.active;
  },

  patientTermineActive: (state) => {
    return state.patient_termine.active;
  },

  timeOutActive: (state) => {
    return state.timeout.active;
  },

  rezeptActive: (state) => {
    return state.rezept.active;
  },

  addIsVisible: (state) => {
    return state.add.visible;
  },

  addIsDisabled: (state) => {
    return state.add.disabled;
  },

  editIsVisible: (state) => {
    return state.edit.visible;
  },

  editIsDisabled: (state) => {
    return state.edit.disabled;
  },

  isRemovable: (state) => (item) => {
    for (const termin of state.add.selected.termine) {
      for (const beh of termin.behandlungen) {
        if (beh.key == item.key) {
          return false;
        }
      }
    }

    return true;
  },

  behandlungenInUse: (state) => (item) => {
    let counter = 0;
    for (const termin of state.add.selected.termine) {
      for (const beh of termin.behandlungen) {
        if (beh.key == item.key) {
          counter += 1;
        }
      }
    }
    return counter;
  },

  behandlungenSelectedLength: (state) => {
    let time_sum = 0;
    for (const beh of state.add.selected.behandlungen_selected) {
      time_sum += parseInt(beh.zeiteinheit, 10);
    }

    let m = time_sum % 60;
    let h = (time_sum - m) / 60;

    return h.toString() + "h " + (m < 10 ? "0" : "") + m.toString() + "min";
  },

  addSelectHeilmittelIsVisible: (state) => {
    return state.add.selectHeilmittelActive;
  },

  moveIsActive: (state) => {
    return state.termin_move.active;
  },

  addIsActive: (state) => {
    return state.add.active;
  },

  editIsActive: (state) => {
    return state.edit.active;
  },

  addIsSelecting: (state) => {
    return state.add.selecting;
  },

  getTerminTypes: (state) => {
    return state.add.termintypes;
  },

  getTerminModes: (state) => {
    return state.add.terminmodes;
  },

  getSelectedTermine: (state) => {
    return state.add.selected.termine;
  },

  getBehandlungen: (state) => {
    return state.add.selected.behandlungen;
  },

  getRemainingBehandlungen: (state) => {
    let behandlungen = state.add.selected.behandlungen;
    let behandlungen_lkp = {};
    behandlungen.forEach(function (value, i) {
      behandlungen_lkp[value.key] = i;
      state.add.selected.behandlungen[i].remaining = value.anzahl;
    });

    for (const termin of state.add.selected.termine) {
      if (state.add.repick_id != termin.id) {
        for (const beh of termin.behandlungen) {
          let i = behandlungen_lkp[beh.key];

          state.add.selected.behandlungen[i].remaining -= 1;
        }
      }
    }

    return state.add.selected.behandlungen.filter((beh) => beh.remaining > 0);
  },

  getBehandlungenDuration: (state) => {
    let duration = 0;
    state.add.selected.behandlungen_selected.forEach((element) => {
      duration += element.zeiteinheit;
    });
    return duration;
  },

  getRepickId: (state) => {
    return state.add.repick_id;
  },

  getPatientId: (state) => {
    return state.add.selected.patient ? state.add.selected.patient.id : null;
  },

  getTerminMoveId: (state) => {
    return state.termin_move.termin.current.id;
  },

  getTreatmentTypes: (state) => {
    return state.add.treatmentTypes;
  },


  getTerminMovePatientId: (state) => {
    const appointment = state.termin_move.termin.current;
    return appointment.patient_data ? appointment.patient_data.patient : null;
  },

  getTerminMoveDuration: (state) => {
    let s = moment(state.termin_move.termin.current.start).utc(true);
    let e = moment(state.termin_move.termin.current.end).utc(true);
    return e.diff(s, "minutes");
  },

  isTerminInPast: () => (date, hour, minute) => {
    let now = moment().utc(true);
    let picked = moment(date)
      .utc(true)
      .hour(hour)
      .minute(minute);

    return picked.isSameOrBefore(now);
  },

  // TODO: Move this to a more appropriate place e.g. appointments module
  // TODO: Replace local checking with server side checking (already implemented on the backend)
  isTerminAvailable: (state, getters, rootState, rootGetters) => (
    date,
    hour,
    minute,
    duration,
    mitarbeiter,
    patientId,
    added_idx
  ) => {
    let data = {
      status: false,
      selected_mitarbeiter: mitarbeiter,
      available_mitarbeiter: [],
      message: "",
    };

    let termin_datetime = moment(date)
      .utc(true)
      .hour(hour)
      .minute(minute);
    let duration_endtime = moment(date)
      .utc(true)
      .hour(hour)
      .minute(minute);

    let now = moment().utc(true);

    // When a duration is given, it is possible to have multiple date and hour keys
    let date_keys = new Set();
    let hour_keys = new Set();

    if (duration) {
      duration_endtime = duration_endtime.add(duration, "m");
      let iterator = moment(date)
        .utc(true)
        .hour(hour)
        .minute(minute);

      while (iterator.isSameOrBefore(duration_endtime, "hour")) {
        date_keys.add(iterator.format("DD.MM.YYYY"));
        hour_keys.add(iterator.format("HH"));
        iterator.add(1, "h");
      }
    } else {
      // No duration info was provided
      date_keys.add(termin_datetime.format("DD.MM.YYYY"));
      hour_keys.add(termin_datetime.format("HH"));
    }

    // Appointment must be set in the future
    if (termin_datetime.isBefore(now)) {
      data.message = "Termin liegt in der Vergangenheit!";
      return data;
    }

    // Cannot be a holiday
    if (rootGetters["holidays/isHoliday"](termin_datetime)) {
      data.message = "Termin liegt an einem Feiertag!";
      return data;
    }

    // Appointment must start and end on the same day
    if (termin_datetime.day() != duration_endtime.day()) {
      data.message = "Tagesübergreifende Termine sind nicht erlaubt!";
      return data;
    }

    // Business hours check
    let business_hours = rootGetters["oeffnungszeiten/getOeffnungszeiten"];
    let start_end_pair = [termin_datetime, duration_endtime];
    let start_end_time_index = [null, null];
    let week_lkp = [6, 0, 1, 2, 3, 4, 5];

    for (let [index, dt] of start_end_pair.entries()) {
      let bh_weekday = week_lkp[dt.day()];

      // Closed for the selected weekday
      if (business_hours[bh_weekday].times.length == 0) {
        if (index == 0) {
          data.message = "Ausgewählter Termin liegt außerhalb unserer Öffnungszeiten!";
        } else {
          data.message = "Ausgewählter Termin würde außerhalb unserer Öffnungszeiten enden!";
        }
        return data;
      }

      // Open for the selected weekday
      let times = business_hours[bh_weekday].times;
      let invalid_time = true;
      for (let [time_index, time] of times.entries()) {
        let [ts_hour, ts_minute] = time[0].split(":").map((e) => parseInt(e));
        let [te_hour, te_minute] = time[1].split(":").map((e) => parseInt(e));

        var ts = moment(dt)
          .hour(ts_hour)
          .minute(ts_minute)
          .second(0);
        var te = moment(dt)
          .hour(te_hour)
          .minute(te_minute)
          .second(0);

        if (dt.isBetween(ts, te, undefined, "[]")) {
          start_end_time_index[index] = time_index;
          invalid_time = false;
          break;
        }
      }

      if (invalid_time) {
        if (index == 0) {
          data.message = "Ausgewählter Termin liegt außerhalb unserer Öffnungszeiten!";
        } else {
          data.message = "Ausgewählter Termin ended außerhalb unserer Öffnungszeiten!";
        }
        return data;
      }
    }

    if (start_end_time_index[0] != start_end_time_index[1]) {
      data.message = "Ausgewählter Termin würde außerhalb unserer Öffnungszeiten stattfinden!";
      return data;
    }

    // First check all day lookup and working hours - only relevant if mitarbeiter was provided
    if (mitarbeiter) {
      if (mitarbeiter in rootGetters["termine/getAllDaybyMitarbeiter"]) {
        for (const key of date_keys) {
          if (rootGetters["termine/getAllDaybyMitarbeiter"][mitarbeiter].has(key)) {
            data.message = "Ausgewählter Termin kann durch den Mitarbeiter nicht wahrgenommen werden!";
            return data;
          }
        }
      }

      let isAvailable = rootGetters["mitarbeiterarbeitszeiten/isEmployeeAvailable"](mitarbeiter, termin_datetime, duration_endtime);
      if (!isAvailable.status) {
        data.message = isAvailable.message;
        return data;
      }
    }

    // Cross check using TerminebyDay, byHour
    let byDay = new Set();
    for (const key of date_keys) {
      let key_data = rootGetters["termine/getTerminebyDay"][key];
      if (key_data) {
        byDay = new Set([...byDay, ...key_data]);
      }
    }

    let byHour = new Set();
    for (const key of hour_keys) {
      let key_data = rootGetters["termine/getTerminebyHour"][key];
      if (key_data) {
        byHour = new Set([...byHour, ...key_data]);
      }
    }

    let intersection = new Set([...byDay].filter((x) => byHour.has(x)));

    // Filter out entries with status "Abgesagt"
    intersection = new Set([...intersection].filter((x) => rootGetters["termine/getTermine"][x].status != "Abgesagt"));


    // Check objects in intersection
    let busy_mitarbeiter = new Set();
    for (const element of intersection) {
      const termin = rootGetters["termine/getTermine"][element];

      if (duration) {
        let duration_datetime = moment(date)
          .utc(true)
          .hour(hour)
          .minute(minute)
          .add(duration, "m");

        let allDay = termin.allDay;

        let s = moment(termin.start).utc(true);
        let e = moment(termin.end).utc(true);

        let busy_a = termin_datetime.isBetween(s, e, undefined, "[)");
        let busy_b = duration_datetime.isBetween(s, e, undefined, "()");
        let busy_c =
          s.isBetween(termin_datetime, duration_datetime, undefined, "[)") ||
          e.isBetween(termin_datetime, duration_datetime, undefined, "())");

        let is_repicked_entry = termin.id == added_idx;

        if (!is_repicked_entry && (busy_a || busy_b || busy_c || allDay)) {
          if (mitarbeiter && mitarbeiter == termin.mitarbeiter) {
            data.message = "Ausgewählter Termin kann durch den Mitarbeiter nicht wahrgenommen werden!";
            return data;
          }

          if (patientId && termin.termintyp == "Behandlung" && termin.patient_data.patient == patientId) {
            data.message = "Patient wird bereits in diesem Zeitraum behandelt!";
            return data;
          }

        }

        if (!is_repicked_entry && (busy_a || busy_b || busy_c || allDay)) {
          busy_mitarbeiter.add(termin.mitarbeiter);
        }
      } else {
        // Only relevant for internal appointments
        // no length information was provided so only the start can be checked
        let allDay = termin.allDay;

        let s = moment(termin.start).utc(true);
        let e = moment(termin.end).utc(true);
        let busy = termin_datetime.isBetween(s, e, undefined, "[)");

        if (mitarbeiter && (busy || allDay)) {
          if (mitarbeiter == termin.mitarbeiter) {
            data.message = "Ausgewählter Termin kann durch den Mitarbeiter nicht wahrgenommen werden!";
            return data;
          }
        }

        if (busy || allDay) {
          busy_mitarbeiter.add(termin.mitarbeiter);
        }
      }
    }

    // check against already selected termine
    let already_selected = getters.getSelectedTermine;
    if (already_selected.length != 0) {
      for (const element of already_selected) {
        if (added_idx != null) {
          if (added_idx == element.id) {
            continue;
          }
        }

        let [h, m] = element.start_time.split(":");

        // element start and end datetime
        let s = moment(element.start_date_str, "DD.MM.YYYY")
          .utc(true)
          .set({ hour: parseInt(h, 10), minute: parseInt(m, 10) });
        let e = moment(element.start_date_str, "DD.MM.YYYY")
          .utc(true)
          .set({ hour: parseInt(h, 10), minute: parseInt(m, 10) })
          .add(duration, "m");

        // selected endtime using duration
        let duration_datetime = moment(date)
          .utc(true)
          .hour(hour)
          .minute(minute)
          .add(duration, "m");

        // check if neither start not end is between element start and end datetime
        let busy_a = termin_datetime.isBetween(s, e, undefined, "[)");
        let busy_b = duration_datetime.isBetween(s, e, undefined, "()");
        let busy_c =
          s.isBetween(termin_datetime, duration_datetime, undefined, "[)") ||
          e.isBetween(termin_datetime, duration_datetime, undefined, "())");


        if (busy_a || busy_b || busy_c) {
          data.message = "Überschneidung mit einem zuvor ausgewählten Termin!";
          return data;
        }
      }
    }

    let available_mitarbeiter = new Set(
      [...rootGetters["mitarbeiter/getMitarbeiterIds"]].filter((x) => !busy_mitarbeiter.has(x))
    );

    let mitarbeiter_to_remove = new Set();
    for (const mitarbeiter in rootGetters["termine/getAllDaybyMitarbeiter"]) {
      for (const key of date_keys) {
        if (rootGetters["termine/getAllDaybyMitarbeiter"][mitarbeiter].has(key)) {
          mitarbeiter_to_remove.add(parseInt(mitarbeiter, 10));
        }
      }
    }

    for (const mitarbeiter of rootGetters["mitarbeiterarbeitszeiten/getUnavailableEmployees"](termin_datetime, duration_endtime)) {
      mitarbeiter_to_remove.add(parseInt(mitarbeiter, 10));
    }

    available_mitarbeiter = new Set([...available_mitarbeiter].filter((x) => !mitarbeiter_to_remove.has(x)));


    if (available_mitarbeiter.size != 0) {
      data.available_mitarbeiter = available_mitarbeiter;
      data.status = true;
    } else {
      data.message = "Ausgewählter Termin kann durch keinen Mitarbeiter wahrgenommen werden!";
    }

    return data;
  },

  getRezeptNacherfassungItem: (state) => {
    return state.rezept.item;
  },

  getAppointmentsData: (state, getters, rootState, rootGetters) => {

    
    let sv = state.add.selected;
    let data = {
      kunde: rootGetters['auth/currentUser'].user_id,
      patient: null,
      incompletePatientData: null,
      termintyp: sv.termintyp,
      termine: [],
      vollprivat: sv.treatmentType == "Vollprivat",
      bemerkung: sv.bemerkung,
    }

    if (sv.termintyp == "Intern") {

      let termin = {
        name: sv.name,
        mitarbeiter: sv.mitarbeiter ? sv.mitarbeiter.id : null,
        ganztag: sv.ganztag,
        color: sv.color.hex,
        start: null,
        end: null,
        behandlungen: null,
      };

      // Create moment objects from date string
      let s = moment(sv.start_date).utc(true);
      let e = moment(sv.end_date).utc(true);

      // If not ganztag, add time information
      if (!sv.ganztag) {
        s.hour(sv.start_time.substring(0, 2)).minute(sv.start_time.substring(3));
        e.hour(sv.end_time.substring(0, 2)).minute(sv.end_time.substring(3));
      }
      else {
        // Set to start and end of day
        s.hour(0).minute(0).second(0);
        e.hour(23).minute(59).second(59);
      }

      // Convert to ISO string
      termin.start = s.toISOString();
      termin.end = e.toISOString();

      data.termine.push(termin);
    }
    else if (sv.termintyp == "Behandlung") {
      // Set patient data
      if (sv.patient) {
        data.patient = sv.patient.id;
      }
      else if (sv.patient_neu_flag) {
        data.incompletePatientData = {
          name: sv.patient_name,
          vorname: sv.patient_vorname,
          geburtsdatum: moment(sv.patient_geburtsdatum).format("DD.MM.YYYY"),
          telefon: sv.patient_telefon,
        };
      }

      for (const element of sv.termine) {
        let terminDuration = 0;
        let counts = {};

        let termin = {
          name: null,
          mitarbeiter: element.mitarbeiter ? element.mitarbeiter.id : null,
          ganztag: false,
          color: "",
          start: null,
          end: null,
          behandlungen: [],
        };

        for (const bh of element.behandlungen) {
          termin.behandlungen.push(
            {
              name: bh.bezeichnung,
              duration: bh.zeiteinheit,
            }
          );

          // Calculate duration and count for each heilmittel
          // This is used to calculate the name of the termin and the end datetime
          var abk = bh.abkuerzung;
          terminDuration += parseInt(bh.zeiteinheit, 10);
          counts[abk] = counts[abk] ? counts[abk] + 1 : 1;
        }

        // Build/Save name
        let name = []
        Object.entries(counts).reduce((parts, [abk, count], index) => {
          if (index !== 0) {
            parts.push("/");
          }
          if (count > 1) {
            parts.push(count.toString() + "x");
          }
          parts.push(abk);
          return parts;
        }, name);

        termin.name = name.join("");

        // Create moment objects from date string
        let s = moment(element.start_date)
          .hour(element.start_time.substring(0, 2))
          .minute(element.start_time.substring(3))
          .utc(true);
        let e = moment(element.start_date)
          .hour(element.start_time.substring(0, 2))
          .minute(element.start_time.substring(3))
          .add(terminDuration, "m")
          .utc(true);

        // Convert to ISO string
        termin.start = s.toISOString();
        termin.end = e.toISOString();
        termin.status = "Temporär"

        data.termine.push(termin);
      }
    }

    return data;
  }

};

const actions = {
  async CreateTermine({ commit, rootGetters, getters, dispatch}) {
    let requestConfig = {
      headers: rootGetters['auth/authHeaders'],
    };

    let appointmentsData = getters.getAppointmentsData

    try {
      await axios.post("termine/", appointmentsData, requestConfig);
      commit("termine/removeTemporaryAppointments", {}, { root: true });
      dispatch("termine/getAppointmentData", null, { root: true })
      commit("closeAddTermin");
      commit("resetState");
      return [{}, appointmentsData]
    } catch (error) {
      return [error.response.data, appointmentsData]
    }

  },

  handleMovedTermin({ commit, state }, data) {
    commit("addMovedTermin", data);
    commit("termine/removeTemporaryAppointments", {}, { root: true });
    commit("termine/addTemporaryAppointment", state.termin_move.termin.new.obj, { root: true });
  },

  handleMoveTerminReturn({ commit }) {
    commit("termine/removeTemporaryAppointments", {}, { root: true });
    commit("closeTerminMoveAndReturn");
  },

  moveOpenDragConfirmation({ commit, state }) {
    let termin_lkp = state.termin_move.termin;
    // Move: same category but different time - move-category: different category with different time
    let type = termin_lkp.current.category == termin_lkp.new.mitarbeiter_str ? "move" : "move-category";

    // Save original data
    let data = {
      type: type,
      termin: termin_lkp.current,
      original_start: moment(termin_lkp.current.start).format("YYYY-MM-DDTHH:mm"),
      original_end: moment(termin_lkp.current.end).format("YYYY-MM-DDTHH:mm"),
      original_category: termin_lkp.current.category,
      errors: [],
      previous: "TerminMove",
    };

    state.termin_move.active = false;

    commit("openDragConfirmation", data);
  },

  closeDragConfirmationAndResetTermin({ commit, state }) {
    if (state.drag_confirmation.active) {

      commit("resetDragTermin")

      commit("closeDragConfirmation");
    }
  },

  closeDragConfirmationAndUpdateTermin({ commit, dispatch, state }) {
    if (state.drag_confirmation.active) {
      if (state.drag_confirmation.move || state.drag_confirmation.move_category) {
        let termin_lkp = state.termin_move.termin;

        state.termin_move.termin.current.start = termin_lkp.new.obj.start;
        state.termin_move.termin.current.end = termin_lkp.new.obj.end;
        state.termin_move.termin.current.category = termin_lkp.new.obj.category;

        commit("termine/removeTemporaryAppointments", {}, { root: true });
      }

      dispatch("termine/updateTerminEntry", state.drag_confirmation.termin, { root: true }).then(() => {
        // Ensure that termin_move is not displayed and data is wiped
        if (state.drag_confirmation.previous) {
          if (state.drag_confirmation.previous == "TerminMove") {
            // After saving show PatientTermine
            state.drag_confirmation.previous = state.termin_move.previous
            // Reset termin_move previous 
            state.termin_move.previous = null;
          }
        }

        commit("closeTerminMoveAndReturn");
        commit("closeDragConfirmation");
      });
    }
  },

  closeAddTerminandReset({ commit, state }) {
    if (state.add.active) {
      commit("closeAddTermin");
      commit("termine/removeTemporaryAppointments", {}, { root: true });
      commit("resetState");
    }
  },

  closeEditTerminandReset({ commit, state }) {
    if (state.edit.active) {
      commit("closeEditTermin");
      commit("resetEditState");
    }
  },

  setTerminRejectId({ commit }, id) {
    commit("setTerminRejectId", id);
  },

  closeTerminReject({ commit, state }) {
    if (state.termin_reject.active) {
      commit("closeTerminReject");
    }
  },

  openAndLoadDragConfirmation({ commit }, data) {
    commit("openDragConfirmation", data);
  },

  async openNacherfassung({ commit }) {
    commit("openNacherfassung");
  },

  async openAndLoadSettings({ commit }) {
    commit("openSettings");
  },

  closeAndClearSettings({ commit }) {
    commit("closeSettings");
    commit("updateSettingsHeilmittel", null);
  },

  closeAndClearNacherfassung({ commit }) {
    commit("closeNacherfassung");
    commit("nacherfassung/clearNacherfassungData", null, { root: true });
  },

  closeAndClearPatientenOverview({ commit }) {
    commit("closePatientenOverview");
    commit("patienten/clearPatienten", null, { root: true });
  },

  closeAndClearPatientTermine({ commit }) {
    commit("closePatientTermine");
  },

  closeAndClearRezept({ commit}) {
    commit("closeRezept");
  },

  async openAndLoadAppointmentEdit({ commit, dispatch}, groupId) {
    dispatch("termine/getAppointmentGroup", groupId, { root: true }).then((data) => {
      commit("openAppointmentEdit", data);
    });
  },

  async closeTimeoutAndRefreshToken({ dispatch, commit }) {
    dispatch("auth/RefreshToken", null, { root: true }).then(() => {
      commit("closeTimeout");
    });
  },
};

const mutations = {
  closeDragConfirmation(state) {
    state.drag_confirmation.active = false;
    state.drag_confirmation.drag = false;
    state.drag_confirmation.drag_category = false;

    state.drag_confirmation.move = false;
    state.drag_confirmation.move_category = false;

    state.drag_confirmation.extend = false;
    state.drag_confirmation.termin = null;
    state.drag_confirmation.original_start = null;
    state.drag_confirmation.original_start = null;
    state.drag_confirmation.original_category = null;
    state.drag_confirmation.errors = [];

    if (state.drag_confirmation.previous) {
      if (state.drag_confirmation.previous == "TerminMove") {
        state.termin_move.active = true;
      } else if (state.drag_confirmation.previous == "PatientTermine") {
        state.patient_termine.active = true;
      }
      else if (state.drag_confirmation.previous == "Nacherfassung") {
        state.nacherfassung.active = true;
      }

      state.drag_confirmation.previous = null;
    }
  },


  resetDragTermin(state) {
    state.drag_confirmation.termin.start = moment(state.drag_confirmation.original_start).format("YYYY-MM-DDTHH:mm:ss");
    state.drag_confirmation.termin.end = moment(state.drag_confirmation.original_end).format("YYYY-MM-DDTHH:mm:ss");
    state.drag_confirmation.termin.category = state.drag_confirmation.original_category;
  },



  openDragConfirmation(state, data) {
    state.drag_confirmation.active = true;
    state.drag_confirmation.termin = data.termin;
    if (data.type == "drag") {
      state.drag_confirmation.drag = true;
    }

    if (data.type == "drag-category") {
      state.drag_confirmation.drag_category = true;
    }

    if (data.type == "extend") {
      state.drag_confirmation.extend = true;
    }

    if (data.type == "move") {
      state.drag_confirmation.move = true;
    }

    if (data.type == "move-category") {
      state.drag_confirmation.move_category = true;
    }

    state.drag_confirmation.original_start = data.original_start;
    state.drag_confirmation.original_end = data.original_end;
    state.drag_confirmation.original_category = data.original_category;
    state.drag_confirmation.errors = data.errors;

    if (data.previous) {
      state.drag_confirmation.previous = data.previous;

      if (data.previous == "TerminMove") {
        state.termin_move.active = false;
      }
    }
  },

  setTerminRejectId(state, id) {
    state.termin_reject.termin_id = id;
  },

  openSettings(state) {
    state.settings.active = true;
  },

  closeSettings(state) {
    state.settings.active = false;
  },

  openNacherfassung(state) {
    state.nacherfassung.active = true;
    state.nacherfassung.loading = true;
  },

  closeNacherfassung(state) {
    state.nacherfassung.active = false;
  },

  openAddPatient(state, item = null) {
    state.add_patient.active = true;
    state.add_patient.item = item;
  },

  openAddDoctor(state, defaults) {
    state.add_doctor.active = true;
    state.add_doctor.defaults = defaults;
  },

  closeAddDoctor(state) {
    state.add_doctor.active = false;
    state.add_doctor.defaults = null;
  },

  addLastAddedDoctor(state, arztNum) {
    state.add_doctor.added.push(arztNum);
  },

  openPatientenOverview(state) {
    state.patienten_overview.active = true;
  },

  closeAddPatient(state) {
    state.add_patient.active = false;
    state.add_patient.item = null;
  },

  closePatientenOverview(state) {
    state.patienten_overview.active = false;
  },

  openAppointmentEdit(state, data) {
    const {termine, ...group} = data;

    state.edit.active = true;
    state.edit.termine = termine
    state.edit.termingruppe = group
  },

  openPatientTermine(state, data) {
    state.patient_termine.active = true;
    state.patient_termine.patient = data.patient;
    state.patient_termine.previous = data.componentName;

    if (data.componentName == "PatientenOverview") {
      state.patienten_overview.active = false;
    }
  },

  closePatientTermine(state) {
    // Hide
    state.patient_termine.active = false;

    // Activate previous view
    if (state.patient_termine.previous == "PatientenOverview") {
      state.patienten_overview.active = true;
    }

    // Reset previous view information
    state.patient_termine.previous = null;
  },

  openAddRezept(state, item) {
    state.rezept.active = true;
    state.rezept.item = item;
  },

  closeRezept(state) {
    state.rezept.active = false;
    state.rezept.item = null;
  },

  openTimeout(state) {
    if (!state.timeout.active) {
      state.timeout.active = true;
    }
  },

  closeTimeout(state) {
    state.timeout.active = false;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  resetStateAdd(state) {
    Object.assign(state.add, getDefaultState().add);
  },

  resetEditState(state) {
    Object.assign(state.edit, getDefaultState().edit);
  },

  clearTimeInput(state) {
    state.add.selected.start_time = null;
    state.add.selected.end_time = null;
  },

  closeAddTermin(state) {
    state.add.actions = false;
    state.add.disabled = false;
    state.add.visible = !state.add.visible;
  },

  closeEditTermin(state) {
    Object.assign(state.edit, getDefaultState().edit);
  },

  openTerminReject(state) {
    state.termin_reject.active = true;
  },

  closeTerminReject(state) {
    state.termin_reject.active = false;
  },

  hideAddTerminShowSelectHeilmittel(state) {
    state.add.visible = false;
    state.add.selecting = false;
    state.snackbars.add_success = false;

    // Set all available Heilmittel as selected
    state.add.selected.behandlungen_selected = this.getters["overlays/getRemainingBehandlungen"];
    state.add.selectHeilmittelActive = true;
  },

  hideAddTerminShowSelectHeilmittelRepick(state, item) {
    state.add.visible = false;

    // Set selected_heilmittel
    state.add.selected.behandlungen_selected = [];
    for (const beh of item.behandlungen) {
      state.add.selected.behandlungen_selected.push(beh);
    }

    state.add.selectHeilmittelActive = true;
    state.add.repicking = true;
    state.add.repick_id = item.id;
  },

  closeSelectHeilmittel(state) {
    state.add.visible = true;
    state.add.selectHeilmittelActive = false;
    state.add.selecting = false;
    state.add.repicking = false;
    state.add.repick_id = null;
  },

  hideAddTerminWithSnackbar(state) {
    state.add.visible = false;
    state.add.selectHeilmittelActive = false;
    state.add.selecting = true;

    if (state.add.repicking) {
      state.snackbars.add_repick = true;
    } else {
      state.snackbars.add_select = true;
    }

    state.snackbars.add_success = false;
  },

  openTerminMove(state, data) {
    // Hide previous views
    if (data.componentName == "PatientTermine") {
      state.patient_termine.active = false;
    }

    if (data.componentName == "Nacherfassung") {
      state.nacherfassung.active = false;
    }

    state.termin_move.previous = data.componentName;
    state.termin_move.active = true;
    state.termin_move.termin.current = data.termin;
  },

  closeTerminMoveAndReturn(state) {
    // Hide termin move
    state.termin_move.active = false;

    // Activate previous view
    if (state.termin_move.previous == "PatientTermine") {
      state.patient_termine.active = true;
    }
    if (state.termin_move.previous == "Nacherfassung") {
      state.nacherfassung.active = true;
    }

    // Reset previous view information
    state.termin_move.previous = null;
    state.termin_move.termin.current = null;
    state.termin_move.termin.new = null;
  },

  showAddTerminWithSnackbarStatus(state, status) {
    state.add.visible = true;

    if (state.add.repicking) {
      state.add.repicking = false;
      state.add.repicking = null;
      state.snackbars.add_repick = false;
    }

    if (state.add.selecting) {
      state.add.selecting = false;
      state.snackbars.add_select = false;
    }

    if (status == "added") {
      state.snackbar.add_success = true;
    }

    if (status == "changed") {
      state.snackbar.change_success = true;
    }
  },

  openAddTermin(state, data) {
    state.add.visible = true;
    state.add.active = true;

    // Set provided data
    if (data && "date" in data) {

      let termin = {
        key: state.add.selected.termin_key,
        id: state.add.selected.termine.length + 1,
        start_date: moment(data.date).utc(true),
        start_date_str: null,
        start_time: data.start_str,
        mitarbeiter: null,
        mitarbeiter_str: "-",
        status: data.status,
        available_mitarbeiter: data.available_mitarbeiter,
      };

      if ("mitarbeiter" in data) {
        // Only relevant for private entries
        state.add.selected.mitarbeiter = data.mitarbeiter;
        state.add.selected.color.hexa = data.mitarbeiter.color;

        termin.mitarbeiter = data.mitarbeiter;
        termin.mitarbeiter_str = data.mitarbeiter.name + ", " + data.mitarbeiter.vorname;
      }

      // Create start_date_str for display value
      termin.start_date_str = termin.start_date.format("DD.MM.YYYY");

      // Update termin_key
      state.add.selected.termin_key += 1;

      // Push termin
      state.add.selected.termine.push(termin);
    }
  },

  addMovedTermin(state, data) {
    // Create termin_move.termin.new lkp used in "moveOpenDragConfirmation"
    let [h_a, m_a] = data.start_str.split(":");
    let termin = {
      start_date: moment(data.date),
      start_time: data.start_str,
      start: moment(data.date)
        .hour(h_a)
        .minute(m_a),
      mitarbeiter: null,
      mitarbeiter_str: "-",
      available_mitarbeiter: data.available_mitarbeiter,
    };

    if ("mitarbeiter" in data) {
      termin.mitarbeiter = data.mitarbeiter;
      termin.mitarbeiter_str = data.mitarbeiter.name + ", " + data.mitarbeiter.vorname;
    }

    let termin_lkp = state.termin_move.termin;

    let duration = 0;
    if ("start" in termin_lkp.current && "end" in termin_lkp.current) {
      duration = moment(termin_lkp.current.end).utc(true) - moment(termin_lkp.current.start).utc(true);
    }
    else {
      let start = moment(termin_lkp.current.startDate)
      start.set({ hour: parseInt(termin_lkp.current.startTime.substring(0,2), 10), minute: parseInt(termin_lkp.current.startTime.substring(3,5), 10) })

      let end = moment(termin_lkp.current.endDate)
      end.set({ hour: parseInt(termin_lkp.current.endTime.substring(0,2), 10), minute: parseInt(termin_lkp.current.endTime.substring(3,5), 10) })

      duration = end - start;
    }

    const s = moment(termin.start).utc(true);
    const e = moment(s).add(duration, "milliseconds");

    termin.obj = JSON.parse(JSON.stringify(termin_lkp.current));
    termin.obj.start = s.format("YYYY-MM-DDTHH:mm");
    termin.obj.end = e.format("YYYY-MM-DDTHH:mm");
    termin.obj.category = termin.mitarbeiter_str;
    termin.obj.status = "Temporär";

    state.termin_move.termin.new = termin;
  },



  addAppointmentsFromSeries(state, data) {
    // Remove existing termine
    state.add.selected.termine = [];

    // Reset unique_id and termin_key
    state.add.selected.termin_key = 0;

    // Prepare treatment buckets
    let treatments = [];
    for (const entry of state.add.selected.behandlungen) {
      treatments.push([entry.anzahl, entry]);
    }

    const patientId = this.getters["overlays/getPatientId"]

    for (const element of data) {

      // Split start datetime into date and time strings
      let [date, time] = element.start.split("T");
      let employee = this.getters["mitarbeiter/getMitarbeiterbyId"](element.employeeId)
      let employeeStr = employee.name + ", " + employee.vorname;

      let termin = {
        key: state.add.selected.termin_key,
        id: -1 * (state.add.selected.termine.length + 1),

        start_date: moment(date).utc(true),
        start_date_str: moment(date).utc(true).format("DD.MM.YYYY"),
        start_time: time.substring(0, 5),
        mitarbeiter: null,
        mitarbeiter_str: "-",
        patient: patientId,

        status: element.valid,
        error_message: element.errors.join("\n"),

        available_mitarbeiter: element.availableEmployees,
        behandlungen: [],
      };

      if (element.valid) {
        termin.mitarbeiter = employee;
        termin.mitarbeiter_str = employeeStr;
      }

      // Set Treatments
      for (const i in treatments) {
        let [count, treatment] = treatments[i];

        if (count > 0) {
          termin.behandlungen.push(treatment);
          treatments[i][0] -= 1;
        }
      }

      // Update termin_key
      state.add.selected.termin_key += 1;

      // Push termin
      state.add.selected.termine.push(termin);

    }

    // Sort termine by start_date
    this.commit("overlays/orderTermine");

    // Reset all temporary appointments
    this.commit("termine/removeTemporaryAppointments", {}, { root: true });

    // Add all termine to temporary appointments
    this.commit("termine/addTemporaryAppointments", state.add.selected.termine, { root: true });
  },

  addTerminToList(state, data) {

    const patientId = this.getters["overlays/getPatientId"]
    const entryId = (state.add.selected.termine.length + 1) * -1;

    let termin = {
      key: state.add.selected.termin_key,
      id: entryId,
      start_date: moment(data.date).utc(true),
      start_date_str: null,
      start_time: data.start_str,
      mitarbeiter: null,
      mitarbeiter_str: "-",
      status: data.status,
      error_message: "",
      available_mitarbeiter: data.available_mitarbeiter,
      behandlungen: [],
      patient: patientId,
    };

    // Create start_date_str for display value
    termin.start_date_str = termin.start_date.format("DD.MM.YYYY");

    for (const b of state.add.selected.behandlungen_selected) {
      termin.behandlungen.push(b);
    }

    // Add mitarbeiter info if present in data object
    if ("mitarbeiter" in data && data.mitarbeiter != null) {
      termin.mitarbeiter = data.mitarbeiter;
      termin.mitarbeiter_str = data.mitarbeiter.name + ", " + data.mitarbeiter.vorname;
    } else {
      termin.status = false;
      termin.error_message = "Keinen Mitarbeiter ausgewählt!";
    }

    // Update termin_key
    state.add.selected.termin_key += 1;

    // Push termin
    state.add.selected.termine.push(termin);

    // Reset selected
    state.add.selected.behandlungen_selected = [];

    // Ensure appointments are sorted by start_date
    this.commit("overlays/orderTermine");

    // Reset all temporary appointments
    this.commit("termine/removeTemporaryAppointments", {}, { root: true });
    
    this.commit("termine/addTemporaryAppointments", state.add.selected.termine, { root: true });

    // Re-enable AddTermin Window
    state.snackbars.add_success = true;
    state.add.visible = true;
    state.add.selecting = false;
    state.snackbars.add_select = false;
    state.snackbars.add_success = true;
  },

  replaceTerminInList(state, data) {
    let idx = (state.add.selected.termine.findIndex((element) => element.id == state.add.repick_id));

    // Replace
    state.add.selected.termine[idx].start_date = moment(data.date).utc(true);
    state.add.selected.termine[idx].start_date_str = state.add.selected.termine[idx].start_date.format("DD.MM.YYYY");
    state.add.selected.termine[idx].start_time = data.start_str;
    state.add.selected.termine[idx].mitarbeiter = null;
    state.add.selected.termine[idx].mitarbeiter_str = "-";
    state.add.selected.termine[idx].status = data.status;
    state.add.selected.termine[idx].available_mitarbeiter = data.available_mitarbeiter;

    // Replace behandlungen
    state.add.selected.termine[idx].behandlungen = [];
    for (const b of state.add.selected.behandlungen_selected) {
      state.add.selected.termine[idx].behandlungen.push(b);
    }

    // Add mitarbeiter info if present in data object
    if ("mitarbeiter" in data) {
      state.add.selected.termine[idx].mitarbeiter = data.mitarbeiter;
      state.add.selected.termine[idx].mitarbeiter_str = data.mitarbeiter.name + ", " + data.mitarbeiter.vorname;
    } else {
      state.add.selected.termine[idx].status = false;
      state.add.selected.termine[idx].error_message = "Keinen Mitarbeiter ausgewählt!";
    }

    // Re-enable AddTermin Window
    state.snackbars.add_success = true;
    state.add.visible = true;
    state.add.repicking = false;
    state.snackbars.add_repick = false;
    state.snackbars.add_success = true;
    state.add.repick_id = null;

    this.commit("overlays/recheckTermine");
  },

  recheckTermin(state, idx) {
    let start_date = moment(state.add.selected.termine[idx].start_date_str, "DD.MM.YYYY")
      .utc(true)
      .format("YYYY-MM-DD");
    let [h, m] = state.add.selected.termine[idx].start_time.split(":");
    h = parseInt(h, 10);
    m = parseInt(m, 10);

    let patientId = state.add.selected.patient

    let mitarbeiter = null;
    if (state.add.selected.termine[idx].mitarbeiter) {
      mitarbeiter = state.add.selected.termine[idx].mitarbeiter.id;
    }

    let duration = this.getters["overlays/getBehandlungenDuration"];
    let available = this.getters["overlays/isTerminAvailable"](
      start_date,
      h,
      m,
      duration,
      mitarbeiter,
      patientId,
      state.add.selected.termine[idx].id
    );
    state.add.selected.termine[idx].available_mitarbeiter = available.available_mitarbeiter;

    if (!mitarbeiter) {
      state.add.selected.termine[idx].status = false;
      state.add.selected.termine[idx].error_message = "Keinen Mitarbeiter ausgewählt!";
    } else {
      state.add.selected.termine[idx].status = available.status;
      if (!available.status) {
        state.add.selected.termine[idx].error_message = available.message;
      }
    }

    // TODO: This solution is sub-optimal, as it will be triggered multiple times (for example when running recheckTermine)
    this.commit("termine/removeTemporaryAppointments", {}, { root: true });
    this.commit("termine/addTemporaryAppointments", state.add.selected.termine, { root: true });
  },

  recheckTermine(state) {
    // Ensure termine are valid and ordered
    if (state.add.selected.termine.length != 0) {
      this.commit("overlays/orderTermine");

      for (let index = 0; index < state.add.selected.termine.length; index++) {
        this.commit("overlays/recheckTermin", index);
      }
    }
    else {
      this.commit("termine/removeTemporaryAppointments", {}, { root: true });
      this.commit("termine/addTemporaryAppointments", state.add.selected.termine, { root: true });
    }
  },

  orderTermine(state) {
    function termine_compare(termin_a, termin_b) {
      let moment_a = moment(termin_a.start_date);
      let moment_b = moment(termin_b.start_date);

      let [h_a, m_a] = termin_a.start_time.split(":");
      moment_a.hour(h_a);
      moment_a.minute(m_a);

      let [h_b, m_b] = termin_b.start_time.split(":");
      moment_b.hour(h_b);
      moment_b.minute(m_b);

      if (moment_a.isBefore(moment_b)) {
        return -1;
      }
      if (moment_a.isAfter(moment_b)) {
        return 1;
      }
      return 0;
    }

    state.add.selected.termine.sort(termine_compare);

    // Update id
    for (let index = 0; index < state.add.selected.termine.length; index++) {
      state.add.selected.termine[index].id = -1 * (index + 1);
    }
  },

  addHeilmittel(state) {
    let hm = { ...state.add.selected.heilmittel };

    if (hm) {
      // Add key property (only needed for data tables)
      hm.key = state.add.selected.behandlungen.length + 1;

      // Add anzahl / remaining property
      hm.anzahl = 1;
      hm.remaining = 1;

      state.add.selected.behandlungen.push(hm);
    }

    this.commit("overlays/recheckTermine");
  },

  removeHeilmittel(state, key) {
    let idx = state.add.selected.behandlungen.findIndex((x) => x.key === key);
    state.add.selected.behandlungen.splice(idx, 1);
  },

  updateBemerkung(state, value) {
    state.add.selected.bemerkung = value;
  },

  updateTermintyp(state, value) {
    state.add.selected.termintyp = value;
  },

  updateTreatmentType(state, value) {
    state.add.selected.treatmentType = value;
  },

  updateTerminmode(state, value) {
    state.add.selected.terminmode = value;
  },

  updatePatientNeu(state, value) {
    state.add.selected.patient_neu = value;
  },

  updatePatient(state, value) {
    state.add.selected.patient = value;
  },

  updateBehandlungen(state, value) {
    state.add.selected.behandlungen = value;
  },

  updateBehandlungenSelected(state, value) {
    state.add.selected.behandlungen_selected = value;
  },

  updateColor(state, value) {
    state.add.selected.color = value;
  },

  updateName(state, value) {
    state.add.selected.name = value;
  },

  updateGanztag(state, value) {
    state.add.selected.ganztag = value;
  },

  updateMitarbeiter(state, value) {
    state.add.selected.mitarbeiter = value;
  },

  updateAddDisabled(state, value) {
    state.add.disabled = value;
  },

  updatePatientNeuName(state, value) {
    state.add.selected.patient_name = value;
  },

  updatePatientNeuVorname(state, value) {
    state.add.selected.patient_vorname = value;
  },

  updatePatientNeuGeburtsdatum(state, value) {
    state.add.selected.patient_geburtsdatum = value;
  },

  updatePatientNeuTelefon(state, value) {
    state.add.selected.patient_telefon = value;
  },

  updateStartDate(state, value) {
    state.add.selected.start_date = value;
  },

  updateEndDate(state, value) {
    state.add.selected.end_date = value;
  },

  updateStartTime(state, value) {
    state.add.selected.start_time = value;
  },

  updateEndTime(state, value) {
    state.add.selected.end_time = value;
  },

  updatePatientNeuFlag(state, value) {
    state.add.selected.patient_neu_flag = value;
  },

  updateHeilmittel(state, value) {
    state.add.selected.heilmittel = value;
  },

  updateSettingsHeilmittel(state, value) {
    state.settings.timeunits.selected.heilmittel = value;
  },

  updateTermine(state, value) {
    state.add.selected.termine = value;
  },

  updateSelectSnackbar(state, value) {
    state.snackbars.add_select = value;
  },

  updateTerminMoveSnackbar(state, value) {
    state.termin_move.active = value;
  },

  updateRepickSnackbar(state, value) {
    state.snackbars.add_repick = value;
  },

  updateSuccessSnackbar(state, value) {
    state.snackbars.success_select = value;
  },

  updateMitarbeiterBusySnackbar(state, value) {
    state.snackbars.mitarbeiter_busy = value;
  },

  updatePastTerminSnackbar(state, value) {
    state.snackbars.past_termin = value;
  },

  removeTermin(state, key) {
    let idx = state.add.selected.termine.findIndex((x) => x.key === key);
    state.add.selected.termine.splice(idx, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
