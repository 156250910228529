import axios from "axios";

const state = {
  nacherfassung: [],
};

const getters = {
  getNacherfassung: (state) => {
    return state.nacherfassung;
  },
};

const actions = {
  async createNacherfassungPatient({ dispatch, rootGetters }, data) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    const response = await axios.post("nacherfassung/patient/", data, config);

    dispatch("termine/getAppointmentData", null, { root: true })

    return response;
  },

  async createNacherfassungPrescription({ dispatch, rootGetters }, data) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    const response = await axios.post("nacherfassung/rezept/", data, config);
    dispatch("termine/getAppointmentData", null, { root: true })

    return response;
  },

  async queryNacherfassung({rootGetters}, params={}) {

    let config = {
      headers: rootGetters['auth/authHeaders'],
      params: params
    }

    try {
      const response = await axios.get("nacherfassung/", config);
      return response.data
    } catch (error) {
        throw Error(error.response.data.message)
    }

  },

};

const mutations = {
  setNacherfassungData(state, data) {
    state.nacherfassung = data.results;
  },

  clearNacherfassungData(state) {
    state.nacherfassung = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
