<template>
  <v-row justify="center">
    <v-dialog
      v-model="active"
      persistent
      max-width="400px"
      transition="dialog-bottom-transition"
    >
      <v-card light>
        <v-card-title>
          <span>Sitzungs Timeout</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row style="text-align:center">
                <v-col cols=12>
Sie werden in Kürze automatisch abgemeldet!
                </v-col>
            </v-row>

            <v-row style="text-align:center">
                <v-col cols=12>
                    {{timeLeft}}
                </v-col>
            </v-row>


          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary-nav-btn" 
            color="primary"
            text
            @click="closeWindow"
          >Angemeledet bleiben</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions} from "vuex";

const initialData = () => ({});

export default {
  name: "TimeOut",
  props: ["updateTimer"],
  data() {
    return initialData();
  },

  computed: {

    timeLeft() {
      return this.$store.getters["auth/getTimeLeftStr"];
    },

    active() {
      return this.$store.getters["overlays/timeOutActive"];
    },



  },

  methods: {
    ...mapActions("overlays", ["closeTimeoutAndRefreshToken"]),

    async closeWindow() {

      // reset data
      const data = initialData();
      Object.keys(data).forEach(k => (this[k] = data[k]));

      await this.closeTimeoutAndRefreshToken();
      this.updateTimer();
    },

  },
  watch: {
  },

  mounted() {}
};
</script>

<style>
</style>