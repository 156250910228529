<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="1500px">
      <v-card light>
        <v-card-title>
          <span>Patienten</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field v-model="search" class="pb-5" append-icon="mdi-magnify" label="Suche" single-line hide-details>
              <template v-slot:append-outer>
                <v-btn small class="ml-2" outlined @click="openAddPatient(null)">
                  <v-icon left> mdi-account-plus</v-icon>
                  Neuer Patient
                </v-btn>
              </template>
            </v-text-field>

            <v-data-table :loading="loading" :headers="headers" :items="patienten" :items-per-page="10"
              class="elevation-1" :footer-props="{
                itemsPerPageText: 'Einträge pro Seite',
                itemsPerPageAllText: 'Alle',
              }" loading-text="Daten werden geladen... Bitte warten" :single-expand="true" :search="search">
              <template #footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} von
                {{ props.itemsLength }}
              </template>

              <template v-slot:item.geburtsdatum="{ item }">
                {{ formatDate(item.geburtsdatum) }}
              </template>

              <template v-slot:item.unfalltag="{ item }">
                {{ formatDate(item.unfalltag) }}
              </template>

              <template slot="no-data">
                <div v-if="patienten.length == 0 && search.length >= 2">
                  Keinen passenden Patienten gefunden!
                </div>
                <div v-if="patienten.length == 0 && search.length < 2">
                  Patienten werden angezeigt sobald ein Suchbegriff eintippt
                  wurde...
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="open(item)" v-bind="attrs" v-on="on">
                      mdi-calendar-search
                    </v-icon>
                  </template>
                  <span>Termine</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-nav-btn" color="primary" text @click="closeWindow">Zurück</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";

const initialData = () => ({
  componentName: "PatientenOverview",
  headers: [
    {
      text: "KT-Typ",
      value: "kt_typ",
      sortable: true,
      align: "start",
    },
    {
      text: "Name",
      value: "name",
      sortable: true,
      align: "start",
    },
    {
      text: "Vorname",
      value: "vorname",
      sortable: true,
      align: "start",
    },
    {
      text: "Geburtsdatum",
      value: "geburtsdatum",
      sortable: true,
    },

    {
      text: "Kostenträger",
      value: "kostentraeger_ik_num",
      sortable: true,
    },

    {
      text: "Vers.-Nr.",
      value: "vers_nummer",
      sortable: true,
    },

    {
      text: "Unfalltag",
      value: "unfalltag",
      sortable: true,
    },

    {
      text: "Aktionen",
      value: "actions",
      sortable: false,
      align: "center",
    },
  ],

  search: "",
  loading: false,
  debouncedSearch: null,
});

export default {
  name: "PatientenOverview",

  data() {
    return initialData();
  },

  created() {
    this.debouncedSearch = debounce(this.performSearch, 500);
  },

  computed: {
    active() {
      return this.$store.getters["overlays/patientenOverviewActive"];
    },

    patienten() {
      return this.$store.getters["patienten/getPatienten"];
    },
  },

  methods: {
    ...mapActions("overlays", ["closeAndClearPatientenOverview"]),
    ...mapActions("patienten", ["Search"]),
    ...mapMutations("overlays", ["openPatientTermine", "openAddPatient"]),
    ...mapMutations("patienten", ["clearPatienten"]),

    formatDate(value) {
      return value ? moment(value).format("DD.MM.YYYY") : "";
    },

    open(item) {
      let data = {
        componentName: this.componentName,
        patient: item,
      };

      this.openPatientTermine(data);
    },

    async performSearch() {
      this.loading = true;

      let searchData = {
            term: this.search,
            includeIncomplete: true,
          }

      await this.Search(searchData);
      this.loading = false;
    },

    closeWindow() {
      // reset data
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));

      this.closeAndClearPatientenOverview();
    },

  },
  watch: {
    async search() {
      if (!this.debouncedSearch) {
        this.debouncedSearch = debounce(this.performSearch, 500);
      }

      if (this.search && this.search.length >= 2) {
        this.debouncedSearch();
      }
      else {
        this.clearPatienten();
      }
    }
  },

  mounted() { },
};

function debounce(func, delay) {
  let timeoutId = null;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}


</script>

<style></style>