import axios from "axios";
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

const getDefaultState = () => {
  return {
    arbeitszeiten: {},
    tempArbeitszeiten: null,
    abwesend: {},
    dirty: false,
    errors: false,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getArbeitszeiten: (state) => {
    return state.arbeitszeiten;
  },

  getAbwesend: (state) => {
    return state.abwesend;
  },

  isDirty: (state) => {
    return state.dirty;
  },

  hasErrors: (state) => {
    return state.errors;
  },

  getUnavailableEmployees: (state) => (start, end) => {
    let unavailableEmployees = [];
    // get all employees with working hours (arbeitszeiten keys)
    let employees = Object.keys(state.arbeitszeiten);

    for (let i = 0; i < employees.length; i++) {
      let employeeId = employees[i];
      let isAvailable = getters.isEmployeeAvailable(state)(employeeId, start, end);
      if (!isAvailable.status) {
        unavailableEmployees.push(employeeId);
      }
    }
    return unavailableEmployees;
  },


  isEmployeeAvailable: (state) => (employeeId, start, end) => {
    let data = {
      status: true,
      message: "",
    }

    // moments.js starts the week with sunday, we need to start with monday
    let weekday = start.isoWeekday() - 1;

    // arbeitszeiten entry : {weekdayNum: 0, label: "Montag", available: true, times: [["08:00", "12:00"], ["14:00", "18:00"]]}
    // 1. find the arbeitszeiten entry for the employee
    let arbeitszeitenEntry = state.arbeitszeiten[employeeId][weekday];

    // 2. Employee is not available on this day (entire day)
    if (!arbeitszeitenEntry.available) {
      data.status = false;
      data.message = "Mitarbeiter ist an diesem Tag nicht verfügbar!";
      return data;
    }

    // 3. Employee is available, so we need to check if the time is within the working hours
    // start and end are moment objects
    // check if both start and end are within the working hours of the employee
    // ensure that they either stand in the same time slot -> if not, there is a gap between the two times
    let timeSlotIndeces = [-1, -1];
    
    // We just ensure to have the same date (which is needed for the comparison)
    // Time will be updated based on the actual slots
    let slotStart = start.clone().set({ hour: 0, minute: 0, second: 0 });
    let slotEnd = start.clone().set({ hour: 0, minute: 0, second: 0 });

    for (let i = 0; i < arbeitszeitenEntry.times.length; i++) {
      let slot = arbeitszeitenEntry.times[i];
      slotStart.set({ hour: slot[0].split(":")[0], minute: slot[0].split(":")[1] });
      slotEnd.set({ hour: slot[1].split(":")[0], minute: slot[1].split(":")[1] });

      if (start.isBetween(slotStart, slotEnd, undefined, "[]")) {
        timeSlotIndeces[0] = i;
      }
      if (end.isBetween(slotStart, slotEnd, undefined, "[]")) {
        timeSlotIndeces[1] = i;
      }

      // We can break if we find just the first slot
      // if the end time is not in the same slot, we know that there is a gap between the two times
      if (timeSlotIndeces[0] !== -1) {
        break;
      }
    }

    // Indeces must be unequal -1 and they must be the same
    let indecesSet = new Set(timeSlotIndeces);
    if (indecesSet.has(-1) || indecesSet.size !== 1) {
      data.status = false;
      data.message = "Termin liegt außerhalb der Mitarbeiterarbeitszeiten!";
      return data;
    }

    return data;
  }


};

const actions = {
  async GetMitarbeiterArbeitszeitenData({ commit, rootGetters }) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    return axios
      .get("mitarbeiterarbeitszeiten/", config)
      .then((res) => {
        commit("resetMitarbeiterArbeitszeiten");
        commit("setMitarbeiterArbeitszeitenData", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async BulkOperationsMitarbeiterArbeitszeiten({ state, commit, rootGetters }) {
    /*
    {
      mitarbeiterIdA: {
        0: {label: "Monday", available: true, times: []}
      },
    }
    */
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    try {
      const response = await axios.post("mitarbeiterarbeitszeiten/bulk/", state.tempArbeitszeiten, config);
      commit("resetMitarbeiterArbeitszeiten");
      commit("setMitarbeiterArbeitszeitenData", response.data);
    } catch (error) {
      throw "Some error " + error;
    }
  },

  setAndCheckTempArbeitszeiten({ commit, rootGetters }, data) {
    const closedTimes = rootGetters["oeffnungszeiten/getGeschlossen"];
    commit("setTempArbeitszeiten", data);
    commit("setTempArbeitszeitenErrors", closedTimes);
  },
};

const mutations = {
  setTempArbeitszeitenErrors(state, closedTimes) {
    for (const entry of Object.values(state.tempArbeitszeiten)) {
      for (const [weekdayNum, weekdayEntry] of Object.entries(entry)) {
        if (weekdayEntry.available) {
          for (const [tempStart, tempEnd] of weekdayEntry.times) {
            const tempInterval = moment.range(moment("2022-01-01 " + tempStart), moment("2022-01-01 " + tempEnd));

            for (const [closedStart, closedEnd] of closedTimes[weekdayNum]) {
              const closedInterval = moment.range(
                moment("2022-01-01 " + closedStart),
                moment("2022-01-01 " + closedEnd)
              );
              if (closedInterval.overlaps(tempInterval)) {
                state.errors = true;
                return;
              }
            }
          }
        }
      }
    }

    state.errors = false;
  },

  setMitarbeiterArbeitszeitenData(state, data) {
    state.arbeitszeiten = {};
    state.abwesend = {};

    for (const [mitarbeiterId, zeiten] of Object.entries(data)) {
      state.arbeitszeiten[mitarbeiterId] = zeiten["workingHours"];
      state.abwesend[mitarbeiterId] = zeiten["unavailable"];
    }
  },

  setTempArbeitszeiten(state, data) {
    state.dirty = true;
    state.tempArbeitszeiten = data;
  },

  resetTempArbeitszeiten(state) {
    state.dirty = false;
    state.tempArbeitszeiten = {};
  },

  resetMitarbeiterArbeitszeiten(state) {
    const data = getDefaultState();
    Object.keys(data).forEach((k) => (state[k] = data[k]));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
