<template>
    <div style="padding-left:2em; padding-right: 1em;">
        <v-row>
            <v-col cols="3">
                <v-menu v-model="menu.startDate" :close-on-content-click="false" transition="scale-transition" offset-x
                    min-width="auto">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="Startdatum" prepend-icon="mdi-calendar" v-model="selections.startDate"
                            v-on="on" class="required" :error-messages="startDateErrors"
                            @input="handleInputDateText('startDate')" @focus="handleFocusDateText('startDate')"
                            @blur="v$.startDate.$touch()" placeholder="TT.MM.JJJJ"></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" @input="handleInputDatePicker('startDate')" first-day-of-week="1"
                        locale="de-de"></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="3">
                <v-text-field ref="starttime" v-model="startTime" prepend-icon="mdi-clock-outline" label="Startzeit"
                    type="time" :error-messages="startTimeErrors" @input="v$.startTime.$touch()" class="required"
                    @blur="v$.startTime.$touch()"></v-text-field>
            </v-col>


            <v-col cols="6" class="d-flex">
                <v-select label="Mitarbeiter" v-model="employeeId" :items="employees" placeholder="Mitarbeiter"
                    prepend-icon="mdi-doctor" :item-text="(item) => item.name + ', ' + item.vorname"
                    :error-messages="employeeIdErrors" :item-value="(item) => item.id" class="required"
                    @blur="v$.employeeId.$touch()">
                </v-select>
            </v-col>

            <v-row justify="space-between" style="padding-right: 12px; padding-left: 12px">

                <v-col cols="6">
                    <v-select v-model="seriesType" :items="seriesTypes" label="Serienmuster"
                        prepend-icon="mdi-alpha-m-box"></v-select>
                </v-col>

                <v-col cols="6" style="padding-top: 25px;" class="text-right">
                    <v-btn @click="prepareDataAndGenerateSeries" :disabled="!isValid" color="primary"
                        :loading="isLoading">Erstellen</v-btn>
                </v-col>
            </v-row>


        </v-row>

        <v-divider></v-divider>

        <v-row v-if="seriesType == 3">
            <v-col cols="12" style="padding-bottom: 0px;">
                <v-radio-group v-model="dailySelection" row>
                    <v-radio label="Intervall" value="interval"></v-radio>
                    <v-radio label="Jeder Arbeitstag" value="dailyWorkingDays"></v-radio>
                </v-radio-group>
            </v-col>


            <v-row align-self="start" style="margin-left: 2em;" v-if="dailySelection == 'interval'">
                <v-col class="shrink"><span style="font-size: 16px;">Jeden/Alle</span></v-col>
                <v-col cols="2" style="top:-3px; position: relative;">
                    <v-text-field single-line dense v-model="interval" type="number" min="1" max="28" step="1"
                        style="margin: 0 !important; padding: 0 !important;" :error-messages="intervalErrors"
                        @blur="v$.interval.$touch()"></v-text-field>
                </v-col>
                <v-col class="shrink"><span style="font-size: 16px">Tag(e)</span></v-col>
            </v-row>

        </v-row>


        <v-row v-if="seriesType == 2">

            <v-row align-self="end" style="margin-left: 2em; padding-top:2.5em; margin-right: 0px;">
                <v-col class="shrink"><span style="font-size: 16px;">Jede/Alle</span></v-col>
                <v-col cols="2" style="top:-3px; position: relative;">
                    <v-text-field single-line dense v-model="interval" type="number" min="1" max="13" step="1"
                        style="margin: 0 !important; padding: 0 !important;" :error-messages="intervalErrors"
                        @blur="v$.interval.$touch()"></v-text-field>
                </v-col>
                <v-col><span style="font-size: 16px">Woche(n) am</span></v-col>
                <v-col cols="6" style="margin-top: 0px !important; padding-top: 0px !important;">
                    <v-select v-model="weekdaySelections" :items="weekdays" label="Wochentag" multiple
                        style="padding-top:4px;" class="required"
                        :error-messages="weekdaySelectionsErrors" @blur="v$.weekdaySelections.$touch()" dense>
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index == 0 || index == 1" small>
                                <span>{{ item.text }}</span>
                            </v-chip>
                            <span v-else-if="index == 2" class="grey--text text-caption">
                                (+{{ weekdaySelections.length - 2 }} weitere)
                            </span>

                        </template>


                    </v-select>
                </v-col>
            </v-row>





        </v-row>

        <v-row v-if="seriesType == 1">
            <v-col cols="12" style="padding-bottom: 0px;">
                <v-radio-group v-model="monthlySelection" row>
                    <v-radio label="Tag der Woche " value="dayOfWeek"></v-radio>
                    <v-radio label="Tag des Monats" value="dayOfMonth"></v-radio>
                </v-radio-group>
            </v-col>


            <v-row align-self="start" style="margin-left: 2em" v-if="monthlySelection == 'dayOfWeek'">
                <v-col class="shrink"><span style="font-size: 16px;">Am</span></v-col>
                <v-col cols="2" style="top:-3px; position: relative;">
                    <v-select v-model="dayOfWeekOccurence" :items="monthlyDayOfWeekOccurence" dense
                        style="margin: 0 !important; padding: 0 !important;"></v-select>
                </v-col>
                <v-col cols="3" style="top:-3px; position: relative;">
                    <v-select v-model="dayOfWeek" :items="weekdays" dense
                        style="margin: 0 !important; padding: 0 !important;"></v-select>
                </v-col>
                <v-col class="shrink"><span style="font-size: 16px">jeden/alle</span></v-col>
                <v-col cols="2" style="top:-3px; position: relative;">
                    <v-text-field single-line dense v-model="interval" type="number" min="1" max="6" step="1"
                        style="margin: 0 !important; padding: 0 !important;" :error-messages="intervalErrors"
                        @blur="v$.interval.$touch()"></v-text-field>
                </v-col>
                <v-col class="shrink"><span style="font-size: 16px">Monat(e)</span></v-col>
            </v-row>

            <v-row align-self="start" style="margin-left: 2em;" v-if="monthlySelection == 'dayOfMonth'">
                <v-col class="shrink"><span style="font-size: 16px;">Am</span></v-col>
                <v-col cols="2" style="top:-3px; position: relative;">
                    <v-text-field single-line dense v-model="dayOfMonth" type="number" min="1" max="31" step="1"
                        style="margin: 0 !important; padding: 0 !important;" :error-messages="dayOfMonthErrors"
                        @blur="v$.dayOfMonth.$touch()"></v-text-field>
                </v-col>
                <v-col cols="2"><span style="font-size: 16px;">Tag jedes</span></v-col>
                <v-col cols="2" style="top:-3px; position: relative;">
                    <v-text-field single-line dense v-model="interval" type="number" min="1" max="6" step="1"
                        style="margin: 0 !important; padding: 0 !important;" :error-messages="intervalErrors"
                        @blur="v$.interval.$touch()"></v-text-field>
                </v-col>
                <v-col cols="2" class="shrink"><span style="font-size: 16px">Monats</span></v-col>
            </v-row>



        </v-row>

        <v-divider style="padding-bottom: 40px; margin-top: 10px;"></v-divider>


    </div>

</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";

import { useVuelidate } from '@vuelidate/core'
import {
    requiredIf,
    required,
    between
} from '@vuelidate/validators'




const defaultSelectionsData = () => ({

    seriesStartDate: null,
    appointmentStartTime: null,
    startDate: null,
})



function vMultiBetween(value) {
    if (this.seriesType == 3) {
        return value >= 1 && value <= 28;
    }
    if (this.seriesType == 2) {
        return value >= 1 && value <= 13;
    }
    if (this.seriesType == 1) {
        return value >= 1 && value <= 6;
    }
    return true;
}


export default {
    name: 'SeriesCreator',

    setup() {
        return { v$: useVuelidate() }
    },


    validations: {
        startDate: {
            required
        },
        startTime: {
            required
        },
        employeeId: {
            required
        },
        interval: {
            required: requiredIf(function () {
                // Daily Interval Series
                const dailyInterval = this.seriesType == 3 && this.dailySelection == 'interval';

                // Weekly Interval Series
                const weeklyInterval = this.seriesType == 2;

                // Monthly Interval Series
                const monthlyInterval = this.seriesType == 1;

                return dailyInterval || weeklyInterval || monthlyInterval;
            }),
            vMultiBetween,
        },
        dayOfMonth: {
            required: requiredIf(function () {
                return this.seriesType == 1 && this.monthlySelection == 'dayOfMonth';
            }),
            between: between(1, 31),
        },
        weekdaySelections: {
            required: requiredIf(function () {
                return this.seriesType == 2;
            }),
        }
    },




    data() {
        return {
            seriesTypes: [
                { "value": 3, "text": "Täglich" },
                { "value": 2, "text": "Wöchentlich" },
                { "value": 1, "text": "Monatlich" },
            ],
            monthlyDayOfWeekOccurence: [
                { "value": 1, "text": "ersten" },
                { "value": 2, "text": "zweiten" },
                { "value": 3, "text": "dritten" },
                { "value": 4, "text": "vierten" },
                { "value": -1, "text": "letzten" },
            ],
            weekdays: [
                { "value": 0, "text": "Montag" },
                { "value": 1, "text": "Dienstag" },
                { "value": 2, "text": "Mittwoch" },
                { "value": 3, "text": "Donnerstag" },
                { "value": 4, "text": "Freitag" },
                { "value": 5, "text": "Samstag" },
                { "value": 6, "text": "Sonntag" },
            ],

            menu: {
                startDate: false,
            },


            selections: defaultSelectionsData(),
            seriesType: 3,
            interval: 1,
            dailySelection: 'interval',
            monthlySelection: 'dayOfWeek',
            dayOfWeekOccurence: 1,
            dayOfWeek: 1,
            dayOfMonth: 1,
            startDate: null,
            startTime: null,
            employeeId: null,
            weekdaySelections: null,

            isLoading: false,

        }
    },

    computed: {
        treatments() {
            return this.$store.state.overlays.add.selected.behandlungen;
        },

        patientId() {
            return this.$store.getters["overlays/getPatientId"]
        },

        patientSelected() {
            let selected = this.$store.state.overlays.add.selected

            const existingPatient = Boolean(selected.patient)
            const newPatient = Boolean(selected.patient_neu_flag && selected.patient_name && selected.patient_vorname && selected.patient_geburtsdatum && selected.patient_telefon)

            return existingPatient || newPatient
        },



        isValid() {
            return !this.v$.$invalid && this.treatments.length > 0 && this.patientSelected;
        },

        employees() {
            return this.$store.getters["mitarbeiter/getMitarbeiter"];
        },


        // Error Messages
        startDateErrors() {
            const errors = []
            if (this.v$) {
                const entry = this.v$.startDate
                if (!entry.$dirty) return errors
                entry.required.$invalid && errors.push('Startdatum fehlt!')
            }
            return errors
        },

        startTimeErrors() {
            const errors = []
            if (this.v$) {
                const entry = this.v$.startTime
                if (!entry.$dirty) return errors
                entry.required.$invalid && errors.push('Startzeit fehlt!')
            }
            return errors
        },

        employeeIdErrors() {
            const errors = []
            if (this.v$) {
                const entry = this.v$.employeeId
                if (!entry.$dirty) return errors
                entry.required.$invalid && errors.push('Mitarbeiter fehlt!')
            }
            return errors
        },

        intervalErrors() {
            const errors = []

            if (this.v$) {
                const entry = this.v$.interval
                if (!entry.$dirty) return errors
                entry.required.$invalid && errors.push('Intervall fehlt!')
                entry.vMultiBetween.$invalid && errors.push('Intervall ungültig!')
            }
            return errors
        },

        dayOfMonthErrors() {
            const errors = []

            if (this.v$) {
                const entry = this.v$.dayOfMonth
                if (!entry.$dirty) return errors
                entry.required.$invalid && errors.push('Tag fehlt!')
                entry.between.$invalid && errors.push('Tag ungültig!')
            }
            return errors
        },

        weekdaySelectionsErrors() {
            const errors = []

            if (this.v$) {
                const entry = this.v$.weekdaySelections
                if (!entry.$dirty) return errors
                entry.required.$invalid && errors.push('Kein Wochentag ausgewählt!')
            }
            return errors
        },

    },

    methods: {

        ...mapMutations("overlays", [
            "addAppointmentsFromSeries",
        ]),

        handleInputDateText(name) {
            if (this.selections[name]) {
                let m = moment(this.selections[name], "DD.MM.YYYY", true);
                if (m.isValid()) {
                    this[name] = m.format("YYYY-MM-DD");

                    setTimeout(() => {
                        this.menu[name] = false;
                    }, 200);
                } else {
                    this[name] = null;
                }
            } else {
                this[name] = null;
            }

            // touch element
            this.v$[name].$touch();
        },

        handleFocusDateText(name) {
            var menu_names = [
                "startDate",
            ].filter((e) => e !== name);

            setTimeout(() => {
                if (!this.menu[name]) {
                    this.menu[name] = true;
                }
                for (const n of menu_names) {
                    this.menu[n] = false;
                }
            }, 200);
        },

        handleInputDatePicker(name) {
            let focus_lkp = {
                startDate: ["starttime"],
            };

            // set selection
            this.selections[name] = moment(this[name]).format("DD.MM.YYYY");

            // close picker
            this.menu[name] = false;

            // focus next element
            let resolution_order = focus_lkp[name];
            for (var element in resolution_order) {
                if (this.$refs[resolution_order[element]]) {
                    let next = resolution_order[element];
                    this.$refs[next].focus();
                    break;
                }
            }
        },

        getDurationBuckets() {
            let buckets = [];
            for (let i = 0; i < this.treatments.length; i++) {
                let treatment = this.treatments[i];
                let bucket = {
                    cnt: treatment.anzahl,
                    duration: treatment.zeiteinheit,
                }
                buckets.push(bucket);
            }
            return buckets;
        },

        async prepareDataAndGenerateSeries() {
            let data = {
                startDate: this.startDate,
                startTime: this.startTime,
                seriesType: this.seriesType,
                durationBuckets: this.getDurationBuckets(),
                employeeId: this.employeeId,
                patientId: this.patientId
            }

            // Daily Series
            if (this.seriesType == 3) {
                if (this.dailySelection == 'interval') {
                    data.interval = parseInt(this.interval);
                } else {
                    data.dailyWorkingDays = true;
                }
            }

            // Weekly Series
            if (this.seriesType == 2) {
                data.interval = parseInt(this.interval);
                data.weeklyDays = this.weekdaySelections;
            }

            // Monthly Series
            if (this.seriesType == 1) {
                data.interval = parseInt(this.interval);
                if (this.monthlySelection == 'dayOfWeek') {
                    data.monthlyNextDayOccurence = this.dayOfWeekOccurence;
                    data.monthlyNextWeekday = this.dayOfWeek;
                } else {
                    data.monthlyDay = this.dayOfMonth;
                }
            }

            this.isLoading = true;
            let response = await this.$store.dispatch("termine/getAppointmentSeries", data);
            // TODO: Add error handling

            this.addAppointmentsFromSeries(response.results);

            this.isLoading = false;
        }

    },

    watch: {
        // Reset Interval when changing Series Type (so that max value is not exceeded)
        seriesType() {
            this.interval = 1;
        }
    },

}




</script>

<style scoped></style>