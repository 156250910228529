<template>
    <v-card flat>
        <v-card-text>

            <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                    <v-select label="Mitarbeiter" v-model="mitarbeiterSelected" :items="mitarbeiter"
                        placeholder="Mitarbeiter auswählen" prepend-icon="mdi-doctor"
                        :item-text="(item) => item.name + ', ' + item.vorname" :item-value="(item) => item.id">
                    </v-select>
                </v-col>
            </v-row>

            <div v-for="(entry, key) in workingHours[mitarbeiterSelected]" class="mb-3" :key="entry.label">
                <v-row>
                    <v-col style="margin-top: 9px; max-width: 12%;">
                        <span style="font-weight: 500; font-size: 16px;">{{
                            entry.label
                        }}</span>
                    </v-col>
                    <v-col cols="2">
                        <v-switch v-model="entry.available" :label="(entry.available && 'Anwesend') || 'Abwesend'"
                            :disabled="isPraxisOpen(entry.weekdayNum)" color="primary" class="mt-2 pt-0" dense
                            hide-details @change="toggleWorkingHourState(key)">
                        </v-switch>
                    </v-col>
                    <v-col cols="6">
                        <div v-for="(value, id) in entry.times" :class="{ 'pb-3': (id != entry.times.length - 1) }"
                            :key="id">

                            <v-menu :ref="'menu-' + key + '-' + id + '-start'"
                                v-model="menus['menu-' + key + '-' + id + '-start']" :close-on-content-click="false"
                                :return-value.sync="value[0]" transition="scale-transition" offset-y max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="value[0]" dense class="mx-4 mt-0" outlined
                                        @change="setAndCheckTempArbeitszeiten(workingHours)" hide-details readonly
                                        v-bind="attrs" v-on="on" label="Anwesend von"
                                        style="display: inline-flex; width: 130px;">
                                    </v-text-field>
                                </template>
                                <v-time-picker v-if="menus['menu-' + key + '-' + id + '-start']" v-model="value[0]"
                                    full-width
                                    @click:minute="saveRefTime('menu-' + key + '-' + id + '-start', value[0])"
                                    :allowed-minutes="allowedMinuteStep" format="24hr" :min="getMinTime(key, id, 0)"
                                    :max="getMaxTime(key, id, 0)">
                                </v-time-picker>
                            </v-menu>

                            -
                            <v-menu :ref="'menu-' + key + '-' + id + '-end'"
                                v-model="menus['menu-' + key + '-' + id + '-end']" :close-on-content-click="false"
                                :return-value.sync="value[1]" transition="scale-transition" offset-y max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="value[1]" dense class="mx-4 mt-0" outlined
                                        @change="setAndCheckTempArbeitszeiten(workingHours)" hide-details readonly
                                        v-bind="attrs" v-on="on" label="Anwesend bis"
                                        style="display: inline-flex; width: 130px;">
                                    </v-text-field>
                                </template>
                                <v-time-picker v-if="menus['menu-' + key + '-' + id + '-end']" v-model="value[1]"
                                    full-width @click:minute="saveRefTime('menu-' + key + '-' + id + '-end', value[1])"
                                    :allowed-minutes="allowedMinuteStep" format="24hr" :min="getMinTime(key, id, 1)"
                                    :max="getMaxTime(key, id, 1)">
                                </v-time-picker>
                            </v-menu>

                            <v-tooltip bottom color="primary"
                                v-if="!isValidInterval(entry.weekdayNum, value, 'error-' + key + '-' + id)">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="red" v-bind="attrs" v-on="on">
                                        mdi-alert
                                    </v-icon>
                                </template>
                                <span>Außerhalb der Öffnungszeiten</span>
                            </v-tooltip>

                            <v-btn icon style="display: inline-flex;" @click="removeTime(key, id)">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>

                            <v-btn v-if="id == entry.times.length - 1 && canAddTime(key)" style="display: inline-flex;"
                                text @click="addTime(key)">
                                Zeit hinzufügen
                            </v-btn>

                        </div>

                    </v-col>
                    <v-spacer></v-spacer>


                </v-row>
            </div>



        </v-card-text>
    </v-card>
</template>

<script>
import moment from "moment"
import { mapActions, mapGetters, mapMutations } from "vuex";


const initialData = () => ({
    weekdays: [
        "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"
    ],

    mitarbeiterSelected: null,
    workingHours: {},
    menus: {},
});

export default {
    name: "MitarbeiterArbeitszeiten",

    data() {
        return initialData();
    },

    computed: {

        mitarbeiter() {
            return this.$store.getters["mitarbeiter/getMitarbeiter"];
        },

        closed() {
            return this.$store.getters["oeffnungszeiten/getOeffnungszeiten"]
        },

    },
    methods: {
        ...mapGetters("mitarbeiterarbeitszeiten", ["getArbeitszeiten"]),
        ...mapMutations("mitarbeiterarbeitszeiten", ["addError", "removeError"]),
        ...mapActions("mitarbeiterarbeitszeiten", ["setAndCheckTempArbeitszeiten"]),

        allowedMinuteStep: m => m % 5 === 0,

        isPraxisOpen(weekdayNum) {
            return !this.$store.getters["oeffnungszeiten/isOpen"](weekdayNum)
        },

        isValidInterval(weekdayNum, timePair) {
            return this.$store.getters["oeffnungszeiten/isValidInterval"](weekdayNum, timePair)
        },

        toggleWorkingHourState(weekday) {
            this.workingHours[this.mitarbeiterSelected][weekday].times = []

            if (this.workingHours[this.mitarbeiterSelected][weekday].available) {
                const firstOpenInterval = this.$store.getters["oeffnungszeiten/getFirstOpenInterval"](weekday)
                this.workingHours[this.mitarbeiterSelected][weekday].times.push(firstOpenInterval)
            }
            this.setAndCheckTempArbeitszeiten(this.workingHours)
        },

        saveRefTime(ref_key, value) {
            this.$refs[ref_key][0].save(value)
            this.setAndCheckTempArbeitszeiten(this.workingHours)
        },


        getMinTime(weekday, entry_id, time_id) {
            var minTime = null

            if (entry_id == 0) {
                if (time_id == 0) {
                    minTime = this.$store.getters["oeffnungszeiten/getMinOpeningHour"](weekday)
                }
                else {
                    minTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id][0]).add(5, "minutes").format("HH:mm")
                }
            }
            else {
                if (time_id == 0) {
                    minTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id - 1][1]).add(5, "minutes").format("HH:mm")
                }
                else {
                    minTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id][0]).add(5, "minutes").format("HH:mm")
                }

            }

            return minTime
        },

        getMaxTime(weekday, entry_id, time_id) {
            var maxTime = null

            if (entry_id == this.workingHours[this.mitarbeiterSelected][weekday].times.length - 1) {
                if (time_id == 1) {
                    maxTime = this.$store.getters["oeffnungszeiten/getMaxOpeningHour"](weekday)
                }
                else {
                    maxTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id][1]).subtract(5, "minutes").format("HH:mm")
                }
            }
            else {
                if (time_id == 0) {
                    maxTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id][1]).subtract(5, "minutes").format("HH:mm")
                }
                else {
                    maxTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times[entry_id + 1][0]).subtract(5, "minutes").format("HH:mm")
                }
            }

            return maxTime
        },

        canAddTime(weekday) {
            var lastTimePairTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times.slice(-1)[0][1])
            var nextValidTime = this.$store.getters["oeffnungszeiten/getNextValidTime"](weekday, lastTimePairTime.add(5, "minutes").format("HH:mm"))
            return Boolean(nextValidTime)
        },

        addTime(weekday) {
            var lastTimePairTime = moment("2022-01-01 " + this.workingHours[this.mitarbeiterSelected][weekday].times.slice(-1)[0][1])
            var nextValidStartTime = this.$store.getters["oeffnungszeiten/getNextValidTime"](weekday, lastTimePairTime.add(5, "minutes").format("HH:mm"))
            var nextValidMaxEndTime = this.$store.getters["oeffnungszeiten/getNextMaxTime"](weekday, nextValidStartTime)

            this.workingHours[this.mitarbeiterSelected][weekday].times.push([nextValidStartTime, nextValidMaxEndTime])
            this.setAndCheckTempArbeitszeiten(this.workingHours)
        },

        removeTime(weekday, time_id) {
            this.workingHours[this.mitarbeiterSelected][weekday].times.splice(time_id, 1)

            if (this.workingHours[this.mitarbeiterSelected][weekday].times.length == 0) {
                this.workingHours[this.mitarbeiterSelected][weekday].available = false
            }
            this.setAndCheckTempArbeitszeiten(this.workingHours)
        },

        setWorkingHours() {
            this.workingHours = JSON.parse(JSON.stringify(this.getArbeitszeiten()))
        },


    },
    watch: {},

    mounted() {
        this.setWorkingHours()
        this.mitarbeiterSelected = this.mitarbeiter[0].id
    },

}

</script>

<style>

</style>