import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },

  theme: {
    themes: {
      light: {
        primary: "#AE2855",
        secondary: "#CB6889",
        tertiary: "#424242",
      },
    },
  },
});
