var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":"Mitarbeiter","items":_vm.mitarbeiter,"placeholder":"Mitarbeiter auswählen","prepend-icon":"mdi-doctor","item-text":function (item) { return item.name + ', ' + item.vorname; },"item-value":function (item) { return item.id; }},model:{value:(_vm.mitarbeiterSelected),callback:function ($$v) {_vm.mitarbeiterSelected=$$v},expression:"mitarbeiterSelected"}})],1)],1),_vm._l((_vm.workingHours[_vm.mitarbeiterSelected]),function(entry,key){return _c('div',{key:entry.label,staticClass:"mb-3"},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"9px","max-width":"12%"}},[_c('span',{staticStyle:{"font-weight":"500","font-size":"16px"}},[_vm._v(_vm._s(entry.label))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"mt-2 pt-0",attrs:{"label":(entry.available && 'Anwesend') || 'Abwesend',"disabled":_vm.isPraxisOpen(entry.weekdayNum),"color":"primary","dense":"","hide-details":""},on:{"change":function($event){return _vm.toggleWorkingHourState(key)}},model:{value:(entry.available),callback:function ($$v) {_vm.$set(entry, "available", $$v)},expression:"entry.available"}})],1),_c('v-col',{attrs:{"cols":"6"}},_vm._l((entry.times),function(value,id){return _c('div',{key:id,class:{ 'pb-3': (id != entry.times.length - 1) }},[_c('v-menu',{ref:'menu-' + key + '-' + id + '-start',refInFor:true,attrs:{"close-on-content-click":false,"return-value":value[0],"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(value, 0, $event)},"update:return-value":function($event){return _vm.$set(value, 0, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-4 mt-0",staticStyle:{"display":"inline-flex","width":"130px"},attrs:{"dense":"","outlined":"","hide-details":"","readonly":"","label":"Anwesend von"},on:{"change":function($event){return _vm.setAndCheckTempArbeitszeiten(_vm.workingHours)}},model:{value:(value[0]),callback:function ($$v) {_vm.$set(value, 0, $$v)},expression:"value[0]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menus['menu-' + key + '-' + id + '-start']),callback:function ($$v) {_vm.$set(_vm.menus, 'menu-' + key + '-' + id + '-start', $$v)},expression:"menus['menu-' + key + '-' + id + '-start']"}},[(_vm.menus['menu-' + key + '-' + id + '-start'])?_c('v-time-picker',{attrs:{"full-width":"","allowed-minutes":_vm.allowedMinuteStep,"format":"24hr","min":_vm.getMinTime(key, id, 0),"max":_vm.getMaxTime(key, id, 0)},on:{"click:minute":function($event){return _vm.saveRefTime('menu-' + key + '-' + id + '-start', value[0])}},model:{value:(value[0]),callback:function ($$v) {_vm.$set(value, 0, $$v)},expression:"value[0]"}}):_vm._e()],1),_vm._v(" - "),_c('v-menu',{ref:'menu-' + key + '-' + id + '-end',refInFor:true,attrs:{"close-on-content-click":false,"return-value":value[1],"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(value, 1, $event)},"update:return-value":function($event){return _vm.$set(value, 1, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-4 mt-0",staticStyle:{"display":"inline-flex","width":"130px"},attrs:{"dense":"","outlined":"","hide-details":"","readonly":"","label":"Anwesend bis"},on:{"change":function($event){return _vm.setAndCheckTempArbeitszeiten(_vm.workingHours)}},model:{value:(value[1]),callback:function ($$v) {_vm.$set(value, 1, $$v)},expression:"value[1]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menus['menu-' + key + '-' + id + '-end']),callback:function ($$v) {_vm.$set(_vm.menus, 'menu-' + key + '-' + id + '-end', $$v)},expression:"menus['menu-' + key + '-' + id + '-end']"}},[(_vm.menus['menu-' + key + '-' + id + '-end'])?_c('v-time-picker',{attrs:{"full-width":"","allowed-minutes":_vm.allowedMinuteStep,"format":"24hr","min":_vm.getMinTime(key, id, 1),"max":_vm.getMaxTime(key, id, 1)},on:{"click:minute":function($event){return _vm.saveRefTime('menu-' + key + '-' + id + '-end', value[1])}},model:{value:(value[1]),callback:function ($$v) {_vm.$set(value, 1, $$v)},expression:"value[1]"}}):_vm._e()],1),(!_vm.isValidInterval(entry.weekdayNum, value, 'error-' + key + '-' + id))?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v("Außerhalb der Öffnungszeiten")])]):_vm._e(),_c('v-btn',{staticStyle:{"display":"inline-flex"},attrs:{"icon":""},on:{"click":function($event){return _vm.removeTime(key, id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1),(id == entry.times.length - 1 && _vm.canAddTime(key))?_c('v-btn',{staticStyle:{"display":"inline-flex"},attrs:{"text":""},on:{"click":function($event){return _vm.addTime(key)}}},[_vm._v(" Zeit hinzufügen ")]):_vm._e()],1)}),0),_c('v-spacer')],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }