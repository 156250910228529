import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import hauptmenu from "./modules/hauptmenu";
import mitarbeiter from "./modules/mitarbeiter";
import zeiteinheiten from "./modules/zeiteinheiten";
import overlays from "./modules/overlays";
import patienten from "./modules/patienten";
import termine from "./modules/termine";
import kostentraeger from "./modules/kostentraeger";
import versicherungsstatus from "./modules/versicherungsstatus";
import nacherfassung from "./modules/nacherfassung";
import icd_codes from "./modules/icd_codes";
import aerzte from "./modules/aerzte";
import diagnosen from "./modules/diagnosen";
import kunde from "./modules/kunde";
import oeffnungszeiten from "./modules/oeffnungszeiten";
import mitarbeiterarbeitszeiten from "./modules/mitarbeiterarbeitszeiten";
import holidays from "./modules/holidays";
import settings from "./modules/settings";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    async GetApplicationData({ dispatch }) {
      return Promise.all([
        dispatch("mitarbeiter/GetMitarbeiterData"),
        dispatch("versicherungsstatus/GetVersicherungsStatusData"),
        dispatch("oeffnungszeiten/GetOeffnungszeitenData"),
        dispatch("mitarbeiterarbeitszeiten/GetMitarbeiterArbeitszeitenData"),
        dispatch("holidays/GetHolidaysData"),
        dispatch("kunde/GetKundenData"),
        dispatch("settings/GetSettingsData"),
        dispatch("termine/getAppointmentData")
      ]).finally(() => {});
    },

    ClearApplicationData({ commit }) {
      commit("overlays/resetState", null, { root: true });
    },
  },
  modules: {
    auth,
    hauptmenu,
    mitarbeiter,
    zeiteinheiten,
    overlays,
    patienten,
    termine,
    kostentraeger,
    versicherungsstatus,
    nacherfassung,
    icd_codes,
    aerzte,
    diagnosen,
    kunde,
    oeffnungszeiten,
    mitarbeiterarbeitszeiten,
    holidays,
    settings,
  },
  plugins: [createPersistedState(
    {
      paths: ['auth', 'hauptmenu']
    }
  )],
});
