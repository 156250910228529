import axios from "axios";

const state = {
  aerzte: [],
};

const getters = {
  getAerzte: (state) => {
    return state.aerzte;
  },
};

const actions = {
  async Search({ commit, rootGetters }, term) {
    if (term.length >= 3) {
      let config = {
        headers: rootGetters['auth/authHeaders'],
        params: {
          search: term,
        },
      };
      const response = await axios.get("aerzte/", config);
      await commit("setAerzte", response.data);
    }
  },

  async findArzt({ rootGetters }, arzt_num) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
      params: {
        arzt_num: arzt_num,
      },
    };

    const response = await axios.get("aerzte/", config);
    return response;
  },

  async createDoctor({ rootGetters }, data) {
    let config = {
      headers: rootGetters['auth/authHeaders'],
    };

    return axios
      .post("aerzte/", data, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

const mutations = {
  setAerzte(state, data) {
    state.aerzte = data;
  },

  clearAerzte(state) {
    state.aerzte = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
