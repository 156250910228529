<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="1200px">
      <v-card light>
        <v-card-title>
          <span>Einstellungen</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <template>
              <v-tabs v-model="settingsTab">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  :disabled="item.disabled"
                  v-for="item in items"
                  :key="item.id"
                >
                  {{ item.text }}
                </v-tab>
              </v-tabs>
            </template>
          </v-container>

          <v-tabs-items v-model="settingsTab" v-if="active">
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="1">
                      <v-avatar
                        color="white"
                        size="100"
                        tile
                        class="elevation-3"
                      >
                        <img v-if="logoUrl" :src="logoUrl" />
                        <v-icon v-if="!logoUrl" dark>
                          mdi-account-circle
                        </v-icon>
                      </v-avatar>
                    </v-col>

                    <v-col cols="4" class="ml-10">
                      <v-file-input
                        class="pt-0"
                        v-model="logoInput"
                        accept="image/jpeg, image/png"
                        label="Bild auswählen"
                        prepend-icon="mdi-image"
                        :disabled="isUploading"
                        :loading="isUploading"
                        counter
                        :error-messages="logoErrors"
                        @change="setLogoSize"
                        @click:clear="clearLogoSize"
                      >
                        <template v-slot:counter>
                          <span
                            v-if="logoErrors.length == 0"
                            class="v-counter"
                            style="color: rgba(0, 0, 0, .6);"
                            >max. 500 x 500</span
                          >
                          <span v-else></span>
                        </template>
                      </v-file-input>
                      <v-btn
                        class="mt-3"
                        color="primary"
                        small
                        :disabled="
                          logoErrors.length > 0 ||
                          !logoInput ||
                          logoInput.length == 0 ||
                          isUploading
                        "
                        @click="uploadUserLogo"
                        :loading="isUploading"
                        >Logo ändern</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="8">
                      <v-autocomplete
                        ref="heilmittel"
                        label="Heilmittel"
                        v-model="heilmittel"
                        :items="heilmittelList"
                        placeholder="Heilmittel auswählen"
                        prepend-icon="mdi-hand-heart"
                        :item-text="heilmittel_text"
                        item-value="id"
                        return-object
                        hide-no-data
                        style="border-radius: 0"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="1" class="">
                      <v-btn
                        small
                        style="top: 20px"
                        outlined
                        @click="addHeilmittel"
                        :disabled="!heilmittel"
                      >
                        <v-icon left> mdi-plus</v-icon>
                        Hinzufügen
                      </v-btn>
                    </v-col>

                    <v-col cols="12" class="pt-0 pb-10">
                      <v-data-table
                        :headers="zeiteinheiten_headers"
                        :items="items.timeunits.selected"
                        :items-per-page="10"
                        class="elevation-1"
                        :footer-props="{
                          itemsPerPageText: 'Einträge pro Seite',
                          itemsPerPageAllText: 'Alle',
                        }"
                      >
                        <template #footer.page-text="props">
                          {{ props.pageStart }}-{{ props.pageStop }} von
                          {{ props.itemsLength }}
                        </template>

                        <template v-slot:item.zeiteinheit="props">
                          <v-edit-dialog
                            :ref="'time_dialog_' + props.item.keygesetzt"
                            :return-value.sync="props.item.zeiteinheit"
                            @close="setZeiteinheitenDirty"
                          >
                            {{ props.item.zeiteinheit }}
                            <template v-slot:input>
                              <v-text-field
                                v-model.number="props.item.zeiteinheit"
                                :rules="[is_number, is_inrange]"
                                label="Edit"
                                single-line
                                counter
                                v-mask="'###'"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>

                        <template slot="no-data">
                          <div>
                            Es wurden noch keine eigenen Zeiteinheiten gesetzt!
                          </div>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="deleteZeiteinheit(item)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                            <span>Löschen</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-alert
                    v-if="this.items.businesshours.error"
                    outlined
                    type="error"
                    prominent
                    border="left"
                  >
                    <div>
                      Die durchgeführten Änderungen stehen in Konflikt mit
                      folgenden Mitarbeiter Arbeitszeiten:
                    </div>

                    <ul>
                      <li
                        v-for="[mitarbeiterId, entry] of Object.entries(
                          this.items.businesshours.arbeitszeitenErrors
                        )"
                        :key="mitarbeiterId"
                      >
                        {{ getMitarbeiterName(mitarbeiterId) }}
                        <ul>
                          <li
                            v-for="[weekdayLabel, times] of Object.entries(
                              entry
                            )"
                            :key="weekdayLabel"
                          >
                            {{ weekdayLabel }}: {{ times }}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </v-alert>

                  <div
                    v-for="(entry, key) in this.items.businesshours.data"
                    class="mb-3"
                    :key="entry.label"
                  >
                    <v-row>
                      <v-col style="margin-top: 9px; max-width: 12%;">
                        <span style="font-weight: 500; font-size: 16px;">{{
                          entry.label
                        }}</span>
                      </v-col>
                      <v-col cols="2">
                        <v-switch
                          v-model="entry.open"
                          :label="(entry.open && 'Offen') || 'Geschlossen'"
                          color="primary"
                          class="mt-2 pt-0"
                          dense
                          hide-details
                          @change="toggleBusinessHourState(key)"
                        >
                        </v-switch>
                      </v-col>
                      <v-col cols="6">
                        <div
                          v-for="(value, id) in entry.times"
                          :class="{ 'pb-3': id != entry.times.length - 1 }"
                          :key="id"
                        >
                          <v-menu
                            :ref="'menu-' + key + '-' + id + '-start'"
                            v-model="
                              items.businesshours.menus[
                                'menu-' + key + '-' + id + '-start'
                              ]
                            "
                            :close-on-content-click="false"
                            :return-value.sync="value[0]"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="value[0]"
                                dense
                                class="mx-4 mt-0"
                                outlined
                                @change="setBusinessHoursDirty()"
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                label="Geöffnet von"
                                style="display: inline-flex; width: 130px;"
                              >
                              </v-text-field>
                            </template>
                            <v-time-picker
                              v-if="
                                items.businesshours.menus[
                                  'menu-' + key + '-' + id + '-start'
                                ]
                              "
                              v-model="value[0]"
                              full-width
                              @click:minute="
                                saveRefTime(
                                  'menu-' + key + '-' + id + '-start',
                                  value[0]
                                )
                              "
                              :allowed-minutes="allowedMinuteStep"
                              format="24hr"
                              :min="getMinTime(key, id, 0)"
                              :max="getMaxTime(key, id, 0)"
                            >
                            </v-time-picker>
                          </v-menu>

                          -
                          <v-menu
                            :ref="'menu-' + key + '-' + id + '-end'"
                            v-model="
                              items.businesshours.menus[
                                'menu-' + key + '-' + id + '-end'
                              ]
                            "
                            :close-on-content-click="false"
                            :return-value.sync="value[1]"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="value[1]"
                                dense
                                class="mx-4 mt-0"
                                outlined
                                @change="setBusinessHoursDirty()"
                                hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                label="Geöffnet bis"
                                style="display: inline-flex; width: 130px;"
                              >
                              </v-text-field>
                            </template>
                            <v-time-picker
                              v-if="
                                items.businesshours.menus[
                                  'menu-' + key + '-' + id + '-end'
                                ]
                              "
                              v-model="value[1]"
                              full-width
                              @click:minute="
                                saveRefTime(
                                  'menu-' + key + '-' + id + '-end',
                                  value[1]
                                )
                              "
                              :allowed-minutes="allowedMinuteStep"
                              format="24hr"
                              :min="getMinTime(key, id, 1)"
                              :max="getMaxTime(key, id, 1)"
                            >
                            </v-time-picker>
                          </v-menu>

                          <v-btn
                            icon
                            style="display: inline-flex;"
                            @click="removeTime(key, id)"
                          >
                            <v-icon dark>
                              mdi-close
                            </v-icon>
                          </v-btn>

                          <v-btn
                            v-if="
                              id == entry.times.length - 1 && canAddTime(key)
                            "
                            style="display: inline-flex;"
                            text
                            @click="addTime(key)"
                          >
                            Zeit hinzufügen
                          </v-btn>
                        </div>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>

                  <div>
                    <v-divider></v-divider>
                    <v-row class="pt-2">
                      <v-col>
                        <v-switch
                          label="Feiertage anzeigen?"
                          color="primary"
                          disabled
                          dense
                          hide-details
                        >
                        </v-switch>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <MitarbeiterArbeitszeiten />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeWindow">Zurück</v-btn>
          <v-btn class="primary-nav-btn" color="primary" text @click="saveSettings" :disabled="!isDirty"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import MitarbeiterArbeitszeiten from "@/components/MitarbeiterArbeitszeiten.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useVuelidate } from "@vuelidate/core";

const initialData = (self) => ({
  settingsTab: null,
  logoInput: [],
  logoSize: {
    width: 0,
    height: 0,
  },
  isUploading: false,
  items: {
    general: {
      id: 0,
      text: "Konto",
      disabled: false,
      dirty: false,
    },
    timeunits: {
      id: 1,
      text: "Zeiteinheiten",
      disabled: false,
      selected: [],
      dirty: false,
    },
    businesshours: {
      id: 2,
      text: "Öffnungszeiten",
      disabled: false,
      data: {},
      menus: {},
      dirty: false,
      error: false,
      arbeitszeitenErrors: {},
    },
    worktimes: {
      id: 3,
      text: "Arbeitszeiten",
      disabled: false,
      dirty: false,
    },
  },

  zeiteinheiten_headers: [
    {
      text: "Bezeichnung",
      align: "start",
      value: "bezeichnung",
    },
    {
      text: "Abkürzung",
      value: "abkuerzung",
    },
    { text: "Dauer (min)", value: "zeiteinheit" },
    { text: "Aktionen", value: "actions", sortable: false },
  ],

  weekdays: [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ],

  search: "",

  is_number: (v) => self.isInteger(v) || "Dauer muss eine Zahl sein!",
  is_inrange: (v) =>
    self.isInRange(v) || "Dauer muss zwischen 0 und 120 Minuten sein!",
});

function imageIsValidSize(value) {
  return value <= 500;
}

export default {
  name: "Settings",
  components: {
    MitarbeiterArbeitszeiten,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations: {
    logoSize: {
      width: { imageIsValidSize },
      height: { imageIsValidSize },
    },
  },

  data() {
    var self = this;
    return initialData(self);
  },

  computed: {
    active() {
      return this.$store.getters["overlays/settingsActive"];
    },

    isDirty() {
      if (this.settingsTab == 2) {
        const isDirty = this.items.businesshours.dirty;
        const hasErrors = this.items.businesshours.error;
        return isDirty && !hasErrors;
      }

      if (this.settingsTab == 3) {
        const isDirty = this.$store.getters["mitarbeiterarbeitszeiten/isDirty"];
        const hasErrors = this.$store.getters[
          "mitarbeiterarbeitszeiten/hasErrors"
        ];
        return isDirty && !hasErrors;
      }

      for (const prop in this.items) {
        if (this.items[prop].id == this.settingsTab) {
          return this.items[prop].dirty;
        }
      }
      return false;
    },

    logoErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.logoSize;
        if (entry.width.$invalid || entry.height.$invalid) {
          errors.push("Logo darf nicht größer als 500x500 sein!");
        }
      }

      return errors;
    },

    logoUrl: {
      get() {
        return this.$store.getters["settings/getLogoUrl"];
      },
    },

    heilmittel: {
      get() {
        return this.$store.state.overlays.settings.timeunits.selected
          .heilmittel;
      },

      set(value) {
        this.$store.commit("overlays/updateSettingsHeilmittel", value, {
          root: true,
        });
      },
    },

    heilmittelList() {
      var selected_bezeichnungen = [];
      for (var heilmittel of this.items.timeunits.selected) {
        selected_bezeichnungen.push(heilmittel.bezeichnung);
      }
      return this.$store.getters["zeiteinheiten/getDefaultZeiteinheiten"](
        selected_bezeichnungen
      );
    },
  },

  methods: {
    ...mapActions("overlays", ["closeAndClearSettings"]),
    ...mapActions("zeiteinheiten", ["BulkOperationsKundenZeiteinheit"]),
    ...mapActions("oeffnungszeiten", ["BulkOperationsOeffnungszeiten"]),
    ...mapActions("mitarbeiterarbeitszeiten", [
      "BulkOperationsMitarbeiterArbeitszeiten",
    ]),
    ...mapMutations("overlays", ["openAddRezept", "openAddPatient"]),
    ...mapMutations("mitarbeiterarbeitszeiten", ["resetTempArbeitszeiten"]),
    ...mapGetters("zeiteinheiten", ["getCustomZeiteinheiten"]),
    ...mapGetters("oeffnungszeiten", ["getOeffnungszeiten"]),
    ...mapGetters("mitarbeiterarbeitszeiten", ["getArbeitszeiten"]),
    ...mapGetters("mitarbeiter", ["getMitarbeiterNamebyId"]),
    ...mapActions("settings", ["UploadLogo"]),

    async uploadUserLogo() {
      const file = this.logoInput;
      this.isUploading = true;
      await this.UploadLogo(file);
      this.logoInput = [];
      this.isUploading = false;
    },

    clearLogoSize() {
      this.logoSize.width = 0;
      this.logoSize.height = 0;
    },

    setLogoSize() {
      if (
        this.logoInput &&
        Object.getPrototypeOf(this.logoInput) == File.prototype
      ) {
        let img = new Image();
        img.onload = () => {
          this.logoSize.width = img.width;
          this.logoSize.height = img.height;
        };
        img.src = URL.createObjectURL(this.logoInput);
      }
    },

    getMitarbeiterName(id) {
      return this.$store.getters["mitarbeiter/getMitarbeiterNamebyId"](id);
    },

    allowedMinuteStep: (m) => m % 5 === 0,

    setSelectedZeiteinheiten() {
      const customZeiteinheiten = this.getCustomZeiteinheiten();
      for (const ze of customZeiteinheiten) {
        this.items.timeunits.selected.push({ ...ze });
      }
    },

    saveRefTime(ref_key, value) {
      this.$refs[ref_key][0].save(value);
      this.setBusinessHoursDirty();
    },

    setBusinessHours() {
      const businesshours = this.getOeffnungszeiten();
      this.items.businesshours.data = JSON.parse(JSON.stringify(businesshours));
    },

    setZeiteinheitenDirty() {
      this.items.timeunits.dirty = true;
    },

    checkBusinessHours() {
      const arbeitszeiten = this.getArbeitszeiten();
      const oeffnungszeiten = this.items.businesshours.data;
      let errorsFound = false;
      let errors = Object.fromEntries(
        Object.keys(arbeitszeiten).map((key) => [key, {}])
      );

      for (const [mitarbeiterId, entry] of Object.entries(arbeitszeiten)) {
        for (const [weekdayNum, weekdayEntry] of Object.entries(entry)) {
          // only check if mitarbeiter is working on that day
          if (weekdayEntry.available) {
            if (!oeffnungszeiten[weekdayNum].open) {
              // add all times
              errorsFound = true;
              errors[mitarbeiterId][weekdayEntry.label] = weekdayEntry.times;
            } else {
              for (const [start, end] of weekdayEntry.times) {
                let isContained = false;
                const intervalStart = moment("2022-01-01 " + start);
                const intervalEnd = moment("2022-01-01 " + end);

                for (const [openStart, openEnd] of oeffnungszeiten[weekdayNum]
                  .times) {
                  const openInterval = moment.range(
                    moment("2022-01-01 " + openStart),
                    moment("2022-01-01 " + openEnd)
                  );
                  if (
                    openInterval.contains(intervalStart) &&
                    openInterval.contains(intervalEnd)
                  ) {
                    isContained = true;
                    break;
                  }
                }

                if (!isContained) {
                  errorsFound = true;
                  if (weekdayEntry.label in errors[mitarbeiterId]) {
                    errors[mitarbeiterId][weekdayEntry.label].push([
                      start,
                      end,
                    ]);
                  } else {
                    errors[mitarbeiterId][weekdayEntry.label] = [[start, end]];
                  }
                }
              }
            }
          }
        }
      }

      this.items.businesshours.error = errorsFound;

      this.items.businesshours.arbeitszeitenErrors = Object.fromEntries(
        Object.entries(errors).filter(
          // eslint-disable-next-line no-unused-vars
          ([key, value]) => Object.keys(value).length !== 0
        )
      );
    },

    setBusinessHoursDirty() {
      this.items.businesshours.dirty = true;
      this.checkBusinessHours();
    },

    isInteger: (str) => {
      var n = Math.floor(Number(str));
      return n !== Infinity && n === str;
    },

    isInRange: (str) => {
      var n = Math.floor(Number(str));
      return n !== Infinity && n === str && n >= 0 && n <= 120;
    },

    heilmittel_text: (item) => item.bezeichnung,

    addHeilmittel() {
      // create copy of heilmittel dont modify the one on the zeiteinheiten object
      this.items.timeunits.selected.push({ ...this.heilmittel });
      this.heilmittel = null;
    },

    closeWindow() {
      // reset data
      const data = initialData(this);
      Object.keys(data).forEach((k) => (this[k] = data[k]));

      this.resetTempArbeitszeiten();
      this.closeAndClearSettings();
    },

    async saveSettings() {
      if (this.isDirty) {
        if (this.settingsTab == 0) {
          // TODO: implement
        } else if (this.settingsTab == 1) {
          const selectedZeiteinheiten = this.items.timeunits.selected;
          await this.BulkOperationsKundenZeiteinheit(selectedZeiteinheiten);
          this.items.timeunits.dirty = false;
        } else if (this.settingsTab == 2) {
          const selectedBusinesshours = this.items.businesshours.data;
          await this.BulkOperationsOeffnungszeiten(selectedBusinesshours);
          this.items.businesshours.dirty = false;
        } else if (this.settingsTab == 3) {
          await this.BulkOperationsMitarbeiterArbeitszeiten();
        }
      }
    },

    deleteZeiteinheit(item) {
      this.items.timeunits.selected = this.items.timeunits.selected.filter(
        (entry) => entry.bezeichnung !== item.bezeichnung
      );
      this.setZeiteinheitenDirty();
    },

    toggleBusinessHourState(weekday) {
      if (!this.items.businesshours.data[weekday].open) {
        this.items.businesshours.data[weekday].times = [];
      } else {
        this.items.businesshours.data[weekday].times = [["06:00", "20:00"]];
      }
      this.setBusinessHoursDirty();
    },

    getMinTime(weekday, entry_id, time_id) {
      var minTime = null;

      if (entry_id == 0) {
        if (time_id == 0) {
          minTime = "00:00";
        } else {
          minTime = moment(
            "2022-01-01 " +
              this.items.businesshours.data[weekday].times[entry_id][0]
          )
            .add(5, "minutes")
            .format("HH:mm");
        }
      } else {
        if (time_id == 0) {
          minTime = moment(
            "2022-01-01 " +
              this.items.businesshours.data[weekday].times[entry_id - 1][1]
          )
            .add(5, "minutes")
            .format("HH:mm");
        } else {
          minTime = moment(
            "2022-01-01 " +
              this.items.businesshours.data[weekday].times[entry_id][0]
          )
            .add(5, "minutes")
            .format("HH:mm");
        }
      }

      return minTime;
    },

    getMaxTime(weekday, entry_id, time_id) {
      var maxTime = null;

      if (entry_id == this.items.businesshours.data[weekday].times.length - 1) {
        if (time_id == 1) {
          maxTime = "23:59";
        } else {
          maxTime = moment(
            "2022-01-01 " +
              this.items.businesshours.data[weekday].times[entry_id][1]
          )
            .subtract(5, "minutes")
            .format("HH:mm");
        }
      } else {
        if (time_id == 0) {
          maxTime = moment(
            "2022-01-01 " +
              this.items.businesshours.data[weekday].times[entry_id][1]
          )
            .subtract(5, "minutes")
            .format("HH:mm");
        } else {
          maxTime = moment(
            "2022-01-01 " +
              this.items.businesshours.data[weekday].times[entry_id + 1][0]
          )
            .subtract(5, "minutes")
            .format("HH:mm");
        }
      }

      return maxTime;
    },

    canAddTime(weekday) {
      var last_timepair = this.items.businesshours.data[weekday].times.slice(
        -1
      )[0];
      var time_end = moment("2022-01-01 " + last_timepair[1]);
      var end_of_day = moment("2022-01-01 23:59");
      var diff = end_of_day.diff(time_end, "minutes");

      return diff >= 6;
    },

    addTime(weekday) {
      var last_timepair = this.items.businesshours.data[weekday].times.slice(
        -1
      )[0];

      var time_end = moment("2022-01-01 " + last_timepair[1]);
      var end_of_day = moment("2022-01-01 23:59");
      var diff = end_of_day.diff(time_end, "minutes");

      var new_time_start = null;
      var new_time_end = null;

      if (diff >= 60) {
        new_time_start = moment(time_end)
          .add(5, "minutes")
          .format("HH:mm");
        new_time_end = moment(time_end)
          .add(1, "hours")
          .format("HH:mm");
      } else {
        new_time_start = moment(time_end)
          .add(5, "minutes")
          .format("HH:mm");
        new_time_end = moment(time_end)
          .add(diff, "minutes")
          .format("HH:mm");
      }

      this.items.businesshours.data[weekday].times.push([
        new_time_start,
        new_time_end,
      ]);
      this.setBusinessHoursDirty();
    },

    removeTime(weekday, time_id) {
      this.items.businesshours.data[weekday].times.splice(time_id, 1);

      if (this.items.businesshours.data[weekday].times.length == 0) {
        this.items.businesshours.data[weekday].open = false;
      }
      this.setBusinessHoursDirty();
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.setSelectedZeiteinheiten();
        this.setBusinessHours();
      }
    },

    settingsTab(val) {
      if (val) {
        if (val == 2) {
          this.checkBusinessHours();
        }
      }
    },
  },
};
</script>

<style></style>
